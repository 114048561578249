import { Article, ArticleOutlined, ChevronLeft, Fingerprint, FingerprintOutlined, ShowChart, ShowChartOutlined } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Button, Container, Fab, Fade, Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DetailsFundamental from '../cards/DetailsFundamental';
import DetailsHistory from '../cards/DetailsHistory';
import DetailsOverview from '../cards/DetailsOverview';
import DetailsScore from '../cards/DetailsScore';
import DetailsWinseries from '../cards/DetailsWinseries';
import MobDetailsFundamental from '../cards/smartphone/MobDetailsFundamental';
import MobDetailsHistory from '../cards/smartphone/MobDetailsHistory';
import MobDetailsOverview from '../cards/smartphone/MobDetailsOverview';
import MobDetailsOverviewLight from '../cards/smartphone/MobDetailsOverviewLight';
import MobDetailsScore from '../cards/smartphone/MobDetailsScore';
import MobDetailsWinseries from '../cards/smartphone/MobDetailsWinseries';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import DialogConfirm from '../elements/DialogConfirm';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

class Instrument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      mobile_view: 'i1',
      posConfirmOpen: false,
      type: 0,
      score: 0,
      idTrade: 0,
      snackOpen: false,
      snackMessage: '',
    }
  }

  switchView(view) {
    this.setState({ view });
  }

  switchMobileView(mobile_view) {
    this.setState({ mobile_view });
  }

  componentDidMount() {
    let { id } = this.props.params;
    this.setState({ id })
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false, posConfirmTradeId: 0 });
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  confirmTradeOpen(id) {
    console.log("apro posizione trade ", this.state.posConfirmTradeId)
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.post(process.env.REACT_APP_API + 'mypos/' + localStorage.getItem('ID') + '/' + id + '/theta2', '', config)
      .then(response => {
        const data = response.data
        if (data > 0) {
          this.setState({ snackOpen: true, snackMessage: t('MyPositions_added_text') });
        } else {
          this.setState({ helpTitle: 'Warning', helpText: 'Non puoi aggiungere altre posizioni', helpOpen: true })
        }
      }).catch(error => {
        console.log(error);
        // HANDLE ERROR HERE (ERROR SNACKBAR)
      })
    this.setState({ posConfirmOpen: false, posConfirmTradeId: id });
  }

  updateScore = (score, type, idTrade) => {
    this.setState({
      score, type, idTrade
    })
  }

  render() {
    const { id, mobile_view, posConfirmOpen, type, score, idTrade, snackOpen, snackMessage } = this.state;
    const hTotal = score ? (window.innerHeight - 56 - 56 - 74) : (window.innerHeight - 56 - 56)
    return (
      <>
        {id && id > 0 ? (
          <Fade in="true" timeout={450} >
            <div>
              <BrowserView>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <Grid container spacing={3} maxWidth={'md'} direction='column' sx={{ mx: 'auto' }}>
                    <Grid item xs container direction={'row'}>
                      <NavLink to='/mybraino'>
                        <Tooltip color='error' title='Back to MyBraino' arrow>
                          <Fab sx={{ mr: 3 }} size='small' variant='circular' color='primary'><ChevronLeft /></Fab>
                        </Tooltip>
                      </NavLink>
                      <Typography className='big' variant='h4'>Instrument details</Typography>
                    </Grid>
                    <Grid item>
                      <DetailsOverview id={id} />
                    </Grid>
                    <Grid item>
                      <DetailsScore id={id} />
                    </Grid>
                    <Grid item>
                      <DetailsWinseries id={id} />
                    </Grid>
                    <Grid item>
                      <DetailsHistory id={id} />
                    </Grid>
                    <Grid item sm={12}>
                      <DetailsFundamental id={id} />
                    </Grid>
                  </Grid>
                </Container>
              </BrowserView>

              <MobileOnlyView>
                <Toolbar />
                <DialogConfirm
                  id={this.state.posConfirmTradeId}
                  closeLoginDialog={() => this.closePosConfirm()}
                  isLoginDialogOpen={posConfirmOpen}
                  confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
                  posDialogTitle="Aggiungi Posizione"
                  posDialogContent="Vuoi aggiungere la posizione alla lista? Per ricevere notifiche sulle posizioni in chiusura è necessario abilitare l'opzione inviami notifiche, newsletter di informativa finanziaria e marketing nelle impostazioni."
                  cancelButton="cancel"
                  okButton="ok" />
                <BrainoSnackbar
                  open={snackOpen}
                  onClose={() => { this.setState({ snackOpen: false }) }}
                  message={snackMessage}
                />
                <Container sx={{ display: 'flex', flexDirection: 'column', height: hTotal, overflow: 'auto', position: 'relative' }}>
                  {mobile_view === 'i1' ? (
                    <Box sx={{pb: 1}}>
                      <MobDetailsOverview id={id} />
                      <MobDetailsScore updateScore={this.updateScore} id={id} />
                    </Box>
                  ) : mobile_view === 'i2' ? (
                    <Box sx={{pb: 1}}>
                      <MobDetailsOverviewLight id={id} />
                      <MobDetailsHistory id={id} />
                    </Box>
                  ) : mobile_view === 'i3' ? (
                    <Box sx={{pb: 1}}>
                      <MobDetailsOverviewLight id={id} />
                      <MobDetailsFundamental id={id} />
                      <MobDetailsWinseries id={id} />
                    </Box>
                  ) : 'No view selected'}
                  <Box sx={{ position: "fixed", left: 0, bottom: 56, width: "100%", p: 2, background: "white", display: (score ? "block" : "none") }}>
                    <Button
                      onClick={() => this.addPosition(idTrade)}
                      disabled={score && idTrade ? false : true}
                      size='large'
                      variant='contained'
                      sx={{ width: "100%" }}
                      color={type === 'B' ? 'buy' : 'sell'}
                      disableElevation>
                      {type === 'B' ? 'Buy' : 'Sell'}
                    </Button></Box>

                  <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                    <BottomNavigation showLabels
                      value={mobile_view}
                      onChange={(event, newValue) => {
                        this.switchMobileView(newValue);
                      }}
                    >
                      <BottomNavigationAction component={NavLink} to="/mybraino" value="0" label="" icon={<ChevronLeft />} />
                      {[
                        { 'l': 'Id', 'v': 'i1', iconOn: <Fingerprint />, iconOff: <FingerprintOutlined color='secondary' /> },
                        { 'l': 'Chart', 'v': 'i2', iconOn: <ShowChart />, iconOff: <ShowChartOutlined color='secondary' /> },
                        { 'l': 'Data', 'v': 'i3', iconOn: <Article />, iconOff: <ArticleOutlined color='secondary' /> },
                      ].map((item) => {
                        const checked = mobile_view === item.v;
                        return (
                          <BottomNavigationAction label={item.l} value={item.v} icon={checked ? item.iconOn : item.iconOff} />
                        )
                      })
                      }
                    </BottomNavigation>
                  </Box>
                </Container>
              </MobileOnlyView>
            </div>
          </Fade>
        ) : 'loading...'
        }
      </>
    );
  }
}

export default withParams(Instrument);