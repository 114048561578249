import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Chart from '../elements/Chart';
import DialogConfirm from '../elements/DialogConfirm';
import DialogHelp from '../elements/DialogHelp';
import SkeletonChart from '../elements/SkeletonChart';

class MyStatsSynthesis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      my_avg_perf: 0,
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      chart_options: 0,
      chart_type: "",
      chart_height: "",
      chart_series: 0
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/myst/wa/synthesis/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data

        var options = {
          series: [{
            name: t('myst_synthesis_chartTitle'),
            data: [
              { 'y': data.averages.my_avg, 'x': t('myst_synthesis_chart_my') },
              { 'y': data.averages.all_avg, 'x': t('myst_synthesis_chart_community') }]
          }],
          chart: {
            type: 'bar',
            height: 125,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: ['#33cd99', '#8d21e9'],
          grid: {
            show: false
          },
          legend: {
            show: false,
          },
          yaxis: {
            categories: [t('myst_synthesis_chart_my'), t('myst_synthesis_chart_community')],
            labels: {
              style: {
                colors: ['#484848'],
                fontSize: '12px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
              show: true
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            labels: {
              show: false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              barHeight: '80%',
              borderRadius: 4,
              dataLabels: {
                position: 'top',
                maxItems: 100,
                hideOverflowingLabels: true,
                orientation: 'horizontal'
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: 8,
            style: {
              fontSize: '14px',
              colors: ['#fafafa'],
              fontFamily: 'Roboto',
              fontWeight: 500,
            },
            background: {
              enabled: false,
              foreColor: '#848484',
              opacity: 0.7,
              padding: 4,
              borderWidth: 0,
            },
            formatter: function (val, opts) {
              return numeral(val).format('+0.0') + '%'
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            enabled: true,
            x: {
              formatter: function (val, opts) {
                return val
              },
            },
            y: {
              formatter: function (val, opts) {
                return numeral(val).format('+0.0') + '%'
              },
            },
            marker: {
              show: false,
            },
          },
        };
        this.setState({
          chart_type: 'bar', chart_height: '125', chart_options: options, data, my_avg_perf: data.averages.my_avg,
          chart_series: [{
            name: t('myst_synthesis_chartTitle'),
            data: [
              { 'y': data.averages.my_avg, 'x': t('myst_synthesis_chart_my') },
              { 'y': data.averages.all_avg, 'x': t('myst_synthesis_chart_community') }]
          }]
        });
        this.setState({ data, isLoading: false })
      }).catch(error => {
        this.setState({ isLoading: false })
        console.log(error);
      }).finally(
        
      )
  }

  render() {
    const { data, my_avg_perf, posConfirmOpen, chart_options, chart_series, isLoading } = this.state;
    const { classes } = this.props;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsSynthesisTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsSynthesisHelp
            </Trans>
          }
        />

        <DialogConfirm
          id={this.state.posConfirmTradeId}
          closeLoginDialog={() => this.closePosConfirm()}
          isLoginDialogOpen={posConfirmOpen}
          confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
          posDialogTitle="Aggiungi Posizione"
          posDialogContent="Vuoi aggiungere la posizione alla lista? Per ricevere notifiche sulle posizioni in chiusura è necessario abilitare l'opzione inviami notifiche, newsletter di informativa finanziaria e marketing nelle impostazioni."
          cancelButton="cancel"
          okButton="ok" />

        <Card variant="outlined" className='fullHeightCard'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MyStatsSynthesisTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {
                  isLoading ? <SkeletonChart height={56} /> :
                    <>
                      <Typography className='big' variant="h3" component="h2" >
                        {my_avg_perf ? numeral(my_avg_perf).format('+0.0') + '%' : '-'}
                      </Typography>
                      <Typography gutterBottom variant="caption" color="secondary" >
                        <Trans>myst_synthesis_profit_label</Trans>
                      </Typography>
                    </>
                }
              </Grid>
              <Grid item xs={6}>
                {
                  isLoading ? <SkeletonChart height={56} /> :
                    <>
                      <Typography className='big' variant="h3" component="h2" >
                        {my_avg_perf ? numeral(data.winrate).format('+0.0') + '%' : '-'}
                      </Typography>
                      <Typography gutterBottom variant="caption" color="secondary" >
                        <Trans>myst_synthesis_winrate_label</Trans>
                      </Typography>
                    </>
                }
              </Grid>
              <Grid item xs={12}>
                {
                  isLoading ? <SkeletonChart height={70} /> :
                    my_avg_perf && chart_options ? (
                      <Chart options={this.state.chart_options} series={chart_series} height={this.state.chart_height} type={this.state.chart_type}></Chart>
                    ) : <Empty />
                }
              </Grid>
            </Grid>
          </CardContent>
          <CardActions></CardActions>
        </Card>
      </>
    );
  }
}

export default MyStatsSynthesis;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img src={'/img/empty/MyStatsSynthesisEmpty.svg'} alt={this.props.img} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans>{t('myst_synthesis_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_synthesis_empty')}</Trans></Typography></Box>
            <Box sx={{mt:2}}><Button component={NavLink} to='/mybraino' variant='outlined'><Trans>{t('myst_synthesis_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}