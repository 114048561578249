import React, { Component } from 'react';
import { Container, Fade, Grid, Toolbar, Typography } from '@mui/material';
import AccountMain from '../cards/AccountMain';
import { Trans } from 'react-i18next';
import { withTheme } from '@emotion/react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { connect } from 'react-redux';
import { setMainTitle } from '../reduxActions/Actions';
import AccountMainMob from '../cards/AccountMainMob';

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: localStorage.getItem('LANG')
    }
  }

  componentDidMount() {
    this.props.dispatch(setMainTitle("My Account"))
  }

  render() {
    return (
      <div>
        <BrowserView>
          <Fade in={true} timeout={450}>
            <div>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'><Trans>account_type</Trans></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AccountMain />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </BrowserView>

        <MobileOnlyView>
          <Fade in={true} timeout={450}>
            <div>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <AccountMainMob />
              </Container>
            </div>
          </Fade>
        </MobileOnlyView>
      </div>
    );
  }
}

export default withTheme(connect()(MyAccount));