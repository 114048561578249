import { withTheme } from '@emotion/react';
import { Balance, BalanceOutlined, Bookmark, BookmarkOutlined, ChevronLeft, History, HistoryOutlined, Timeline, TimelineOutlined } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Chip, Container, Fade, Grid, Toolbar, Typography } from '@mui/material';
import React, { Component } from 'react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import MyStatsBalance from '../cards/MyStatsBalance';
import MyStatsBreakdown from '../cards/MyStatsBreakdown';
import MyStatsEquityLine from '../cards/MyStatsEquityLine';
import MyStatsRanking from '../cards/MyStatsRanking';
import MyStatsSynthesis from '../cards/MyStatsSynthesis';
import MyStatsTrades from '../cards/MyStatsTrades';
import MobMyStatsBalance from '../cards/smartphone/MobMyStatsBalance';
import MobMyStatsEquityLine from '../cards/smartphone/MobMyStatsEquityLine';
import MobMyStatsSynthesis from '../cards/smartphone/MobMyStatsSynthesis';
import MobMyStatsTrades from '../cards/smartphone/MobMyStatsTrades';
import notificationsInit from '../commons/Notifications';
import IntroMyStats from '../intro/IntroMyStats';
import MobIntroMyStats from '../intro/MobIntroMyStats';
import { notifyLoginUpdates, notifyLogout, setMainTitle } from '../reduxActions/Actions';


class MyBraino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMyStatsClickable: true,
      myStatsColor: 'primary',
      isMainClickable: false,
      mainColor: 'default',
      view: 'main',
      mobile_view: '1',

      schedeOpen: false,

      reloadMyPositions: false,
      reloadMyFavorites: false,
      reloadMyAdvices: false,

      selected: 'main',

      introOpen: localStorage.getItem("ONB_MYSTATS") ? false : true
    }
  }
  handleNotificationAdvicesChange() {
    this.setState({ reloadMyAdvices: true });
    setTimeout(function () { this.setState({ reloadMyAdvices: false }) }.bind(this), 1000);
  }

  handleNotificationNewPosition() {
    this.setState({ reloadMyPositions: true });
    setTimeout(function () { this.setState({ reloadMyPositions: false }) }.bind(this), 1000);
  }

  handleNotificationFavoriteChange() {
    this.setState({ reloadMyFavorites: true, reloadMyAdvices: true });
    setTimeout(function () { this.setState({ reloadMyFavorites: false, reloadMyAdvices: false }) }.bind(this), 1000);
  }

  switchView(view) {
    this.setState({ view, selected: view });
  }

  switchMobileView(mobile_view) {
    this.setState({ mobile_view, selected: mobile_view });
  }

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) { 
      this.props.dispatch(notifyLogout()) 
      this.props.dispatch(notifyLoginUpdates()) 
    }
    this.props.dispatch(setMainTitle("My Stats"))
    // notificationsInit(localStorage.getItem('MSGT'));
  }

  closeSchede() {
    this.setState({ schedeOpen: false })
  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  render() {
    const { mobile_view, introOpen } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          <BrowserView>
            <IntroMyStats
              open={introOpen}
              introClose={() => this.introClose()}
              title={'Intro'}
            />
            <Toolbar />
            <Container sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="h4" className='big'>My Statistics</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign='right'>
                  <Chip
                    clickable
                    component={NavLink} to={'/mybraino'} button
                    sx={{ mr: 1 }}
                    size='medium'
                    variant={'plain'}
                    color={'primary'}
                    label='Go to MyBraino'
                  />
                </Grid>
                <Grid item xs={6}><MyStatsSynthesis></MyStatsSynthesis></Grid>
                <Grid item xs={6}><MyStatsBalance></MyStatsBalance></Grid>
                <Grid item xs={12}><MyStatsEquityLine></MyStatsEquityLine></Grid>
                <Grid item xs={6}><MyStatsBreakdown></MyStatsBreakdown></Grid>
                <Grid item xs={6}><MyStatsRanking></MyStatsRanking></Grid>
                <Grid item xs={12}><MyStatsTrades></MyStatsTrades></Grid>
              </Grid>
            </Container>
          </BrowserView>

          <MobileOnlyView>
            <Toolbar />
            <MobIntroMyStats
              open={introOpen}
              introClose={() => this.introClose()}
              title={'Intro'}
            />
            <Container sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", overflow: 'auto', position: 'relative' }}>
              {mobile_view === '1' ? (
                <Box sx={{ pb: 1 }}>
                  <MobMyStatsSynthesis />
                  <MobMyStatsBalance />
                </Box>
              ) : mobile_view === '2' ? (
                <Box sx={{ pb: 1 }}>
                  <MobMyStatsEquityLine />
                </Box>
              ) : mobile_view === '3' ? (
                <Box sx={{ pb: 1 }}>
                  <MobMyStatsTrades />
                </Box>
              ) : 'No view selected'}
              <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                <BottomNavigation showLabels
                  value={mobile_view}
                  onChange={(event, newValue) => {
                    this.switchMobileView(newValue);
                  }}
                >
                  <BottomNavigationAction component={NavLink} to="/mybraino" value="0" label="" icon={<ChevronLeft />} />
                  {[
                    { 'l': 'Balance', 'v': '1', iconOn: <Balance />, iconOff: <BalanceOutlined color='secondary' /> },
                    { 'l': 'P&L', 'v': '2', iconOn: <Timeline />, iconOff: <TimelineOutlined color='secondary' /> },
                    { 'l': 'Trades', 'v': '3', iconOn: <History />, iconOff: <HistoryOutlined color='secondary' /> },
                  ].map((item) => {
                    const checked = mobile_view === item.v;
                    return (
                      <BottomNavigationAction label={item.l} value={item.v} icon={checked ? item.iconOn : item.iconOff} />
                    )
                  })
                  }
                </BottomNavigation>
              </Box>
            </Container>
          </MobileOnlyView>
        </div >
      </Fade>);
  }
}

export default withTheme(connect()(MyBraino));