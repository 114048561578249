import React, { Component } from 'react';
import { Grid, Typography, Container, Fade, Fab, Tooltip, Toolbar, Box, BottomNavigation, BottomNavigationAction, Divider } from '@mui/material';
import MzEquityLine from '../cards/MzEquityLine';
import MobMzEquityLine from '../cards/smartphone/MobMzEquityLine';
import MobMzReturn from '../cards/smartphone/MobMzReturn';
import MobMzAllocation from '../cards/smartphone/MobMzAllocation';
import MobMzAllocationDetails from '../cards/smartphone/MobMzAllocationDetails';
import MzAllocation from '../cards/MzAllocation';
import MzReturn from '../cards/MzReturn';
import MzFingerprint from '../cards/MzFingerprint';
import MzPerformances from '../cards/MzPerformances';
import MzRiskAnalysis from '../cards/MzRiskAnalysis';
import MzDrawdown from '../cards/MzDrawdown';
import { BrowserView, MobileOnlyView, TabletView } from 'react-device-detect';
import { NavLink, useParams } from 'react-router-dom';
import { ChevronLeft, DonutLargeOutlined, BarChartOutlined, SsidChartOutlined, KeyboardArrowLeft, HexagonOutlined, SsidChart, DonutLarge, BarChart, Hexagon, DonutSmall } from '@mui/icons-material'
import MobMzPerformancesDetails from '../cards/smartphone/MobMzPerformancesDetails';
import MobMzRiskAnalysis from '../cards/smartphone/MobMzRiskAnalysis';
import MobMzFingerprint from '../cards/smartphone/MobMzFingerprint';
import MobMzDrawdown from '../cards/smartphone/MobMzDrawdown';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Portfolios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      mobile_view: 1
    }
  }

  backToPortfolios() {
    window.location.href = '/portfolios'
  }

  componentDidMount() {
    localStorage.setItem("TITLE", "My Portfolios")
    let { id } = this.props.params;
    this.setState({ id })
  }

  switchMobileView(mobile_view) {
    this.setState({ mobile_view });
  }

  render() {
    const { mobile_view, id } = this.state;
    return (
      <>
        {id && id > 0 ? (
          <Fade in={true} timeout={450}>
            <div>
              <BrowserView>
                <Toolbar />
                <Container sx={{ p: 2 }}>
                  <Grid container spacing={3} direction="row" alignItems="stretch">
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                      <NavLink to='/portfolios'>
                        <Tooltip color='error' title='Back to Portfolios' arrow>
                          <Fab sx={{ mr: 3 }} size='small' variant='circular' color='primary'><ChevronLeft /></Fab>
                        </Tooltip>
                      </NavLink>
                      <Typography variant="h4" className='big'>Portfolio details</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={5}>
                      <Grid item xs={12} style={{ marginBottom: 24 }}>
                        <MzReturn id={id} prefix="mz_today"></MzReturn>
                      </Grid>
                      <Grid item xs={12}>
                        <MzFingerprint id={id} prefix="mz_fingerprint"></MzFingerprint>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={7}>
                      <MzEquityLine id={id} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                      <MzAllocation id={id} prefix="mz_portfAllocation"></MzAllocation>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                      <MzPerformances id={id} prefix="mz_performance"></MzPerformances>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                      <MzRiskAnalysis id={id} prefix="mz_risk"></MzRiskAnalysis>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                      <MzDrawdown id={id} prefix="mz_drawdown"></MzDrawdown>
                    </Grid>
                  </Grid>
                </Container>
              </BrowserView>

              <TabletView>ah, this is a tablett!</TabletView>

              <MobileOnlyView>
                <Box sx={{ /*overflow: 'hidden', maxHeight: "100vh", height: "100vh"*/ }}>
                  <Toolbar />
                  <Container sx={{ p: 0 }}>
                    <Grid container>
                      {mobile_view === 1 ? (
                        <Grid xs={12} sx={{ p: 0 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", overflow: 'hidden', position: 'relative' }}>
                            <Box sx={{ height: "50%", display: 'flex', flexDirection: 'column',  }}>
                              <Box sx={{height: 72}}><MobMzReturn id={id} /></Box>
                              <Box sx={{flex: 1}}><MobMzEquityLine id={id} /></Box>
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', px: 2 }}><MobMzDrawdown id={id} /></Box>
                          </Box>
                        </Grid>
                      ) : mobile_view === 2 ? (
                        <Grid xs={12} sx={{ p: 0 }}>
                          {/* 100vh - header - bottomMenu - top container padding */}
                          <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", overflow: 'hidden', position: 'relative' }}>
                            <Box sx={{ flex: '60%', height: '60%', p: 2 }}>
                              <MobMzAllocation id={id}></MobMzAllocation>
                            </Box>
                            <Box sx={{ flex: '40%', position: 'relative' }}>
                            </Box>
                            <MobMzAllocationDetails id={id}></MobMzAllocationDetails>
                          </Box>
                        </Grid>
                      ) : mobile_view === 3 ? (
                        <Grid xs={12} sx={{ p: 0 }}>
                          <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", position: 'relative' }}>
                            <Box sx={{ flex: "50%" }}>
                              <MobMzPerformancesDetails prefix="mz_performance" id={id} />
                            </Box>
                            <Box sx={{ flex: "50%", position: 'relative' }}>
                              <MobMzRiskAnalysis id={id} />
                            </Box>
                          </Box>
                        </Grid>
                      ) : mobile_view === 4 ? (
                        <Grid xs={12} sx={{ p: 0 }}>
                          <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", position: 'relative' }}>
                            <Box sx={{ height: '100%' }}>
                              <MobMzFingerprint prefix="mz_performance" id={id} />
                            </Box>
                          </Box>
                        </Grid>
                      ) : 'No view selected'}
                    </Grid>
                    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                      <BottomNavigation
                        value={mobile_view}
                        onChange={(event, newValue) => {
                          this.switchMobileView(newValue);
                        }}
                        showLabels
                      >
                        <BottomNavigationAction component={NavLink} to="/portfolios" label={""} value="0" icon={<KeyboardArrowLeft />} />
                        {[
                          { 'l': 'P&L', 'v': 1, iconOn: <SsidChart />, iconOff: <SsidChartOutlined /> },
                          { 'l': 'Allocation', 'v': 2, iconOn: <DonutSmall />, iconOff: <DonutLargeOutlined /> },
                          { 'l': 'Stats', 'v': 3, iconOn: <BarChart />, iconOff: <BarChartOutlined /> },
                          { 'l': 'Compare', 'v': 4, iconOn: <Hexagon sx={{ rotate: '90deg', fontSize: 20 }} />, iconOff: <HexagonOutlined sx={{ rotate: '90deg', fontSize: 20 }} /> },
                        ].map((item) => {
                          const checked = mobile_view === item.v;
                          return (
                            <BottomNavigationAction label={item.l} value={item.v} icon={checked ? item.iconOn : item.iconOff} />
                          )
                        })
                        }
                      </BottomNavigation>
                    </Box>
                  </Container>
                </Box>
              </MobileOnlyView>

            </div>
          </Fade>
        ) : 'loading...'}
      </>
    );
  }
}

export default withParams(Portfolios);