import React from 'react';
import { Box } from "@mui/material";
import { Skeleton } from '@mui/material';

const SkeletonListAvatar = (props) => {
    const printSkeleton = (cb) => {
        const row = [];
        for (let i = 0; i < (props.rows ? props.rows : 5); i++) {
            row.push(
                <Box display="flex" alignItems="center" flexDirection="row" height="72px">
                    <Box paddingRight="16px">
                        <Skeleton variant="circle" width={40} height={40} />
                    </Box>
                    <Box display="flex" alignItems="start" flexDirection="column" width="100%">
                        <Skeleton variant="text" width="30%" />
                        <Skeleton variant="text" width="10%" />
                    </Box>
                </Box>
            );
        }
        return row;
    };

    return (
        <>{printSkeleton()}</>
    );
}

export default SkeletonListAvatar;