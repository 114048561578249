import { CheckOutlined } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { t } from 'i18next';

class ProfilingMarkets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: []
    }
  }

  componentDidMount() {
  }

  handleChange(value) {
    let selected = this.state.selected
    if (!selected.includes(value)) {
      selected.push(value)
    } else {
      selected = selected.filter(function (v, index, arr) {
        return v !== value;
      })
    }
    this.setState({ selected })
    this.props.handleChange(this.props.q, selected)
    this.props.handleStatus(this.props.q, selected.length > 0 ? true : false)
  }

  render() {
    const { selected } = this.state;
    return (
      <Box sx={{ py: 2 }}>
        <Typography gutterBottom variant='body2'><Trans>q_3</Trans></Typography>
        <Grid container spacing={1}>
          {[
            { 'l': t("a_zone_ita"), 'v': 1 },
            { 'l': t("a_zone_eur"), 'v': 2 },
            { 'l': t("a_zone_asi"), 'v': 3 },
            { 'l': t("a_zone_emg"), 'v': 4 },
            { 'l': t("a_zone_nam"), 'v': 5 },
            { 'l': t("a_zone_sam"), 'v': 6 },
          ].map((item) => {
            const checked = selected.includes(item.v);
            return (
              <Grid item xs={6} sm={4} md={4} lg={3}>
                <Chip
                  sx={{ m: 1, minWidth: "100%" }}
                  size='medium'
                  key={item.l}
                  variant={checked ? 'plain' : 'outlined'}
                  color={checked ? 'primary' : 'secondary'}
                  label={item.l}
                  icon={checked ? <CheckOutlined /> : ''}
                  onClick={() => {
                    this.handleChange(item.v);
                  }}
                >
                </Chip>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

export default withTranslation()(ProfilingMarkets);