import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Chip, Grid } from '@mui/material';
import axios from 'axios';
import I18n from '../../commons/Utils';
import DialogHelp from '../../elements/DialogHelp';
import ReactApexChart from 'react-apexcharts';
import numeral from 'numeral';
import { t } from 'i18next';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import SkeletonChart from '../../elements/SkeletonChart';

class MobMzAllocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      sectors: [],
      isMarketClickable: true,
      marketColor: 'primary',
      isSectorClickable: false,
      sectorColor: 'default',
      helpOpen: false,
      detailsOpen: false,
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      options: null,

      selected: 'sector'
    }
  }

  getAllocation(type) {
    numeral.options.scalePercentBy100 = false
    type = type ? type : 'sector';
    this.setState({ selected: type, isLoading: true });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'v2/mz/wa/portfolio_allocation_' + type + '/' + this.props.id, config)
      .then(response => {
        const data = response.data
        var data_sectors = data.sectors;
        var series = [], labels = [];
        for (var i = 0; i < data_sectors.length; i++) {
          series.push(parseFloat(data_sectors[i].allocation))
          labels.push(data_sectors[i].group_name)
        }

        var options = {
          series: series,
          labels: labels,
          chart: {
            type: 'pie',
            animations: {
              enabled: true,
              easing: 'easeout',
              speed: 450,
              animateGradually: {
                enabled: true,
                delay: 50
              },
              dynamicAnimation: {
                enabled: false,
              }
            },
          },
          legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'left',
            floating: false,
            itemMargin: {
              vertical: 8
            },
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0.0[0]%');
              }
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Roboto, sans-serif',
              colors: ['#fff']
            },
            dropShadow: {
              enabled: false
            }
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
              expandOnClick: true,
              offsetX: 0,
              offsetY: 0,
              customScale: 1,
              // size: 200,
              dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
              },
              donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                      return val
                    }
                  },
                  value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 400,
                    offsetY: 16,
                    formatter: function (val) {
                      return numeral(val).format('0.00%')
                    }
                  },
                  total: {
                    show: false,
                    showAlways: false,
                    label: 'Total',
                    fontSize: '16px',
                    fontFamily: 'Roboto, sans-serif',
                    fontWeight: 600,
                    color: '#373d3f',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b
                      }, 0)
                    }
                  }
                }
              },
            }
          },
          theme: {
            monochrome: {
              enabled: true,
              color: this.props.theme.palette.chartPrimary,
              shadeTo: 'light',
              shadeIntensity: 0.6
            }
          }
        };
        this.setState({ data, options, isLoading: false });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
  }

  async componentDidMount() {
    this.getAllocation()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.chart_options !== this.state.chart_options) {
      this.getAllocation()
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  render() {
    const { data, isLoading, selected, options } = this.state;
    console.log(isLoading)
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={this.state.helpTitle}
          helpDialogContent={this.state.helpText}
          okButton="ok" />

        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography gutterBottom variant="overline" component="h2">
              <b><Trans>MobMzAllocationTitle</Trans></b>
            </Typography>
          </Box>
          
          {isLoading ? <Box sx={{ width: '100%', height: '100%', p: 2 }}><SkeletonChart height={"100%"} /></Box>
            : data ? (
              <Box sx={{ height: '100%' }}>
                <ReactApexChart options={options} series={options.series} type='donut' height={'100%'} />
              </Box>
            )
              : <div>no data</div>}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Grid container maxWidth={200} spacing={1}>
              {[
                { 'l': t('mz_portfAllocation_button_sectors'), 'v': 'sector' },
                { 'l': t('mz_portfAllocation_button_markets'), 'v': 'market' },
              ].map((item, i) => {
                const checked = selected === item.v;
                return (
                  <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Chip
                      sx={{ width: "100%", maxWidth: 92 }}
                      size='medium'
                      key={item.l}
                      variant={checked ? 'plain' : 'outlined'}
                      color={checked ? 'primary' : 'secondary'}
                      label={item.l}
                      onClick={() => {
                        this.getAllocation(item.v);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

        </Box>
      </>
    );
  }
}

export default withTheme(MobMzAllocation);
