import { withTheme } from '@emotion/react';
import { CheckOutlined, DoneAll, GolfCourse, MarkEmailReadOutlined, Star, Whatshot, WhatshotOutlined } from '@mui/icons-material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import { getFlag } from '../commons/Utils';
import BrainoTooltip from '../elements/BrainoTooltip';
import DialogConfirm from '../elements/DialogConfirm';
import DialogHelp from '../elements/DialogHelp';
import FullDialog from '../elements/FullDialog';
import SkeletonList from '../elements/SkeletonListAvatar';
import DialogUpgrade from '../elements/DialogUpgrade';
import { LoadingButton } from '@mui/lab';

const StyledRating = Rating;

class MyAdvices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      posConfirmOpen: false,
      upgradeOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      upgradeTitle: t("MyPositions_limit_reached_text"),
      upgradeText: <Trans commands={{ br: <br /> }}>MyPositions_too_many_text</Trans>,

      instrument: 0,
      schedeOpen: false,

      alarmOpen: false,
      snackOpen: false,
      snackMessage: '',
    }
  }

  confirmTradeOpen(id) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.post(process.env.REACT_APP_API + 'mypos/' + localStorage.getItem('ID') + '/' + id + '/theta2', '', config)
      .then(response => {
        const data = response.data
        if (data > 0) {
          // this.props.notifyNewPosition(id);
          this.setState({ snackOpen: true, snackMessage: t('MyPositions_added_text') });
          this.loadAdvices()
        } else {
          this.setState({ upgradeOpen: true })
        }
      }).catch(error => {
        console.log(error);
        // HANDLE ERROR HERE (ERROR SNACKBAR)
      })
    this.setState({ posConfirmOpen: false, posConfirmTradeId: id });
  }

  openPosConfirm() {
    this.setState({ posConfirmOpen: true });
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false, posConfirmTradeId: 0 });
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  closeUpgradeDialog() {
    this.setState({ upgradeOpen: false })
  }

  openUpgradeDialog() {
    this.setState({ upgradeOpen: true })
  }

  openSchede(id) {
    this.setState({ schedeOpen: true, instrument: id })
  }
  closeSchede() {
    this.setState({ schedeOpen: false })
  }

  closeAlarm = () => {
    this.setState({ alarmOpen: false })
  }

  loadAdvices() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'myb/open_advice2/', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }
  async componentDidMount() {
    this.loadAdvices()
    this.loadEmailNotifications()
  }

  loadEmailNotifications() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        this.setState({ alarmOpen: data === "1" ? false : true })
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  enableEmailNotifications = () => {
    this.setState({ isLoading: true })
    const value = 1
    let config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } }
    axios.put(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID') + '/' + value, null, config)
      .then(response => {
        this.setState({ alarmOpen: false, snackOpen: true, snackMessage: t("settings_mail_notifications_on") })
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.neededReload && this.props.neededReload) {
      console.log(prevProps.neededReload, this.props.neededReload, 'reload Advices')
      this.loadAdvices()
    }
  }

  render() {
    const { data, posConfirmOpen, schedeOpen, instrument, snackOpen, snackMessage, isLoading, alarmOpen } = this.state;

    return (
      <div>
        <FullDialog open={schedeOpen} instrument={instrument} close={() => this.closeSchede()}></FullDialog>

        <Dialog
          open={alarmOpen}
          fullWidth={true}
          close={this.closeAlarm}
        >
          <DialogTitle>{t("my_mypos_alert_title")}</DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="body2"><Trans>my_mypos_alert</Trans></Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAlarm} color='inherit' sx={{ mr: 1 }}><Trans>my_mypos_alert_btn_2</Trans></Button>
            <LoadingButton loading={isLoading} loadingPosition="start" startIcon={<MarkEmailReadOutlined />} onClick={this.enableEmailNotifications} variant='contained' disableElevation><Trans>my_mypos_alert_btn_1</Trans></LoadingButton>
          </DialogActions>
        </Dialog>

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyAdvicesTitle")}
          helpDialogContent={
            <Trans
              components={{
                done_all: <DoneAll color='primary' />,
                whatshot: <WhatshotOutlined color='secondary' />,
                star: <Star color='warning' />,
                small: <small />,
                em: <em />,
                mailTo_professionals: <a target="_blank" rel="noopener noreferrer" href='mailto:professionals@wavenure.com?subject=Information request'> </a>,
              }}
            >
              {"MyAdvicesHelp"}
            </Trans>
          }
          okButton="ok" />

        <DialogConfirm
          id={this.state.posConfirmTradeId}
          closeLoginDialog={() => this.closePosConfirm()}
          isLoginDialogOpen={posConfirmOpen}
          confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
          posDialogTitle={t('my_addconfirmTitle')}
          posDialogContent={<Trans components={{ i: <i></i> }}>my_addconfirm_desc</Trans>}
          cancelButton="cancel"
          okButton="ok" />

        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <DialogUpgrade
          closeUpgradeDialog={() => this.closeUpgradeDialog()}
          isUpgradeDialogOpen={this.state.upgradeOpen}
          upgradeDialogTitle={this.state.upgradeTitle}
          upgradeDialogContent={this.state.upgradeText}
        />

        <Card variant='outlined'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2">{t("MyAdvicesTitle")}</Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{ height: '770px', overflow: 'auto' }}>
            <List >
              {
                isLoading ? <SkeletonList></SkeletonList> :
                  data.length > 0 ? data.map((obj) => (
                    <div>
                      <ListItem button key={obj.id_trade} component={NavLink} to={"/detail/" + obj.id_instr} selected={obj.is_fav ? 1 : 0}>
                        <ListItemAvatar ><Avatar sx={{ width: 30, height: 30 }} alt={obj.id_trade} src={getFlag(obj.country)} /></ListItemAvatar>
                        <ListItemText
                          primary={<Typography variant="subtitle2">{obj.name}</Typography>}
                          secondary={
                            obj.advice_type === 1 ?
                              <div><DoneAll color='primary' sx={{ mr: 2, fontSize: 20 }} />
                                <StyledRating value={obj.score} max={3} readOnly size="small" />
                              </div>
                              : <div><Whatshot sx={{ mr: 2 }} color='secondary' />
                                <StyledRating value={obj.score} max={3} readOnly size="small" />
                              </div>
                          } />
                        <ListItemSecondaryAction>
                          <BrainoTooltip title="Add to my position" arrow>
                            <Button
                              disabled={obj.is_traded ? 1 : 0}
                              size='small'
                              disableElevation
                              variant='contained'
                              color={!obj.is_traded ? (obj.open_signal === 'B' ? 'buy' : 'sell') : "secondary"}
                              onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation(); // stop click event from bubbling up to ListItemButton
                                this.addPosition(obj.id_trade);
                              }}
                            >{obj.open_signal === 'B' ? 'Buy' : 'Sell'}</Button>
                          </BrainoTooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </div>
                  )) : <Empty />
              }
            </List>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withTheme(MyAdvices);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ p: 2, flex: '40%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyAdvicesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='body2'><Trans>{t('MyAdvices_empty')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}