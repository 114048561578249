import React, { Component } from 'react';
import { Typography, Slide, List, ListItem, ListItemSecondaryAction, ListItemText, Box, ListItemAvatar, Chip, Tooltip, Avatar, IconButton, SwipeableDrawer, Grid, Button, ListItemButton, CircularProgress, Skeleton } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import SkeletonListAvatar from '../../elements/SkeletonListAvatar';
import { VerticalAlignBottom, VerticalAlignTop, Inventory2Outlined, AlarmOnOutlined, FlagOutlined, DirectionsRunOutlined, KeyboardArrowUp, DragHandle, QueryStatsOutlined, Leaderboard, DragIndicator, HealingOutlined, HelpOutline, HelpOutlineOutlined } from '@mui/icons-material';
import { COLORS } from '../../commons/Colors';
import { withTheme } from '@emotion/react';
import DialogTradeMob from '../../elements/DialogTradeMob';
import EmptyContent from '../../commons/EmptyContent';
import { NavLink } from 'react-router-dom';
import { getFlag } from '../../commons/Utils';
import { DraggableCore } from 'react-draggable';
import { FixedSizeList } from 'react-window';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import DialogHelp from '../../elements/DialogHelp';
import { DragElement } from '../../commons/DragElement';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const numberFormatter = item => numeral(item).format("0,00,0.00");

class MobMyPositions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      collapsed: true,
      yStart: (window.innerHeight - 56 - 56) / 2, // initial position of draggable component
      yPos: (window.innerHeight - 56 - 56) / 2,
      yMax: 0,
      yDir: 0,
      listHeight: 0,
      helpOpen: false,
      posDialogOpen: false,
      posDialogTitle: "",
      posDialogHist: [],
      posDialogData: [],
      max_min_items: [],
      idTrade: 0,
      idInstr: 0,

      neededReload: this.props.neededReload,
      tradeStatuses: {
        "ts2": <Tooltip title='Closing today'><AlarmOnOutlined sx={{ fontSize: '100%' }} /></Tooltip>,
        "ts1": <Tooltip title='Just added'><FlagOutlined sx={{ fontSize: '100%' }} color='primary' /></Tooltip>,
        "ts-1": <Inventory2Outlined sx={{ fontSize: '100%' }} />,
        "ts0": <Tooltip title='In progress'><DirectionsRunOutlined sx={{ fontSize: '100%' }} color='primary' /></Tooltip>,
      },
      selected: 'op'
    }

    this.draggableRef = React.createRef();
    this.parentRef = React.createRef();
    this.containerRef = React.createRef();
    this.handleClose = this.handleStop.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  loadPositions(tp) {
    console.log("loadPositions")
    this.setState({ isLoading: true, data: [] });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    tp = tp ? tp : this.state.selected
    let data = axios.get(process.env.REACT_APP_API + 'myposs/' + localStorage.getItem('ID') + '/theta2/' + tp, config)
      .then(response => {
        const data = response.data
        this.setState({ data, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  loadPosition = (idTrade, idInstr) => {
    this.setState({ posDialogOpen: true, idTrade, idInstr });
  }

  openPosConfirm() {
    this.setState({ posConfirmOpen: true });
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false });
  }

  closePos() {
    this.setState({ posDialogOpen: false });
    this.setState({ posDialogTitle: "" });
    this.setState({ posDialogHist: [] });
    this.setState({ posDialogData: [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.neededReload) {
      console.log(prevProps.neededReload)
      this.loadPositions()
    }
  }

  async componentDidMount() {
    const { current: container } = this.containerRef;
    if (container) {
      const windowHeight = window.innerHeight;
      const headerHeight = 56;
      const footerHeight = 56;
      const listHeight = windowHeight - headerHeight - footerHeight - container.offsetTop;
      this.setState({ listHeight });
    }

    this.loadPositions()
  }

  changeType(tp) {
    this.setState({ selected: tp })
    this.loadPositions(tp)
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed
    }));
  }

  handleStart = (event, ui) => {
    this.setState({
      collapsed: true,
    })
  }

  handleDrag = (event, ui) => {
    const { yStart } = this.state;
    const newY = ui.y;
    const parentTop = this.parentRef.current.getBoundingClientRect().top - 56
    if (newY <= parentTop) {
      this.setState({
        yPos: parentTop,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: false,
      });
    } else if (newY < yStart) {
      this.setState({
        yPos: newY,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    } else {
      this.setState({
        yPos: yStart,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    }
  }

  handleStop = (event, ui) => {
    const { yDir, yStart, yMax } = this.state
    if (yDir > 5) {
      this.setState({
        yPos: yStart,
        collapsed: true,
      })
    } else if (yDir < 5) {
      this.setState({
        yPos: yMax,
        collapsed: false,
      })
    }
  }

  render() {
    const { data, posDialogOpen, options, posDialogHist, posDialogTitle, posDialogData, isLoading, max_min_items, selected, idTrade, idInstr, collapsed, yPos, listHeight } = this.state;
    let positions = data.list ? data.list.pos : []
    const bg = "white"
    const overflow = collapsed ? "hidden" : "auto"

    const Row = ({ index, style }) => {
      const obj = positions[index];
      return (
        <ListItemButton style={style} gutterBottom key={obj.id_trade} onClick={() => this.loadPosition(obj.id_trade, obj.id_instr)}>
          <ListItemAvatar>
            <Avatar alt={obj.country} src={getFlag(obj.country)} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography sx={{ whiteSpace: 'nowrap', maxWidth: '70%', overflow: "hidden", textOverflow: "ellipsis" }}><b>{obj.name}</b></Typography>}
            secondary={<Typography variant="caption" color={'secondary'}><Chip sx={{ borderRadius: 1, height: 'auto', width: 50, opacity: .6 }} size='small' label={obj.dir} color={obj.dir === 'long' ? 'buy' : 'sell'} /></Typography>} />
          <ListItemSecondaryAction sx={{ textAlign: 'right' }}>
            <Typography variant="subtitle2">{obj.perc_gain}</Typography>
            {this.state.tradeStatuses['ts' + obj.trade_status]}
          </ListItemSecondaryAction>
        </ListItemButton>
      );
    };

    return (
      <div>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyPositionsTitle")}
          helpDialogContent={
            <Trans
              components={{
                flag: <FlagOutlined color='primary' />,
                directions_run: <DirectionsRunOutlined color='primary' />,
                alarm_on: <AlarmOnOutlined color='error' />,
                inventory_2: <Inventory2Outlined />,
                em: <em />,
                healing: <HealingOutlined color='warning' />,
              }}
            >
              {"MyPositionsHelp"}
            </Trans>
          }
          okButton="ok" />

        <DialogTradeMob
          open={posDialogOpen}
          closePos={() => this.closePos()}
          TransitionComponent={Transition}
          posDialogData={posDialogData}
          posDialogHist={posDialogHist}
          posDialogTitle={posDialogTitle}
          max_min_items={max_min_items}
          options={options}
          idTrade={idTrade}
          idInstr={idInstr}
          theme={this.props.theme}
        />

        <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: "calc(100vh - 56px - 56px)", overflow: 'hidden', position: 'relative' }}>
          <Box sx={{ p: 2, display: 'flex', flex: '50%', position: 'relative', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center' }}><Typography variant="h4" className='big'>Hello</Typography></Box>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <IconButton component={NavLink} to={'/mystats'} color='primary'><Leaderboard /></IconButton>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ maxHeight: '240px' }} src="img/MyPositions.svg" />
              </Box>
              <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='caption' color="textSecondary">You have</Typography>
                <Typography variant='h2' className='big' data-testid="positions">{isLoading ? <Skeleton width={60} /> : positions.length}</Typography>
                <Typography variant='caption' color="textSecondary">open positions</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flex: '50%', position: 'relative' }}></Box>

          <DraggableCore
            axis="y" handle=".handle" onDrag={this.handleDrag} onStop={this.handleStop} onStart={this.handleStart}
          >
            <Box ref={this.draggableRef} style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              cursor: 'move',
              border: '1px solid #e6e6e6',
              borderBottom: 0,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8, background: bg, maxHeight: '100%', top: yPos, transition: "all 300ms cubic-bezier(.22,.68,0,1.4)"
            }}>
              <Box className='handle' sx={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                background: bg,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 2,
              }}>
                <DragElement />
              </Box>
              <Box sx={{ height: "calc(100% - 56px)" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: '50%' }}>
                    <Typography sx={{ px: 2, }} gutterBottom variant="overline">{t("MyPositionsTitle")}</Typography>
                  </Box>
                  <Box sx={{ flex: '50%', display: "flex", justifyContent: "end", mr: 1 }}>
                    <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                      <HelpOutlineOutlined />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', py: 2 }}>
                  <Grid container maxWidth={300} spacing={1}>
                    {[
                      { 'l': t("my_mypos_chip1"), 'v': 'op' },
                      { 'l': t("my_mypos_chip2"), 'v': 'cling' },
                      { 'l': t("my_mypos_chip3"), 'v': 'cl' },
                    ].map((item) => {
                      const checked = selected === item.v;
                      return (
                        <Grid key={item.v} item xs={4}>
                          <Chip
                            sx={{ width: "100%", maxWidth: 92 }}
                            size='medium'
                            key={item.l}
                            disabled={isLoading ? true : false}
                            variant={checked ? 'plain' : 'outlined'}
                            color={checked ? 'primary' : 'secondary'}
                            label={item.l}
                            onClick={() => {
                              this.changeType(item.v);
                            }}
                          >

                          </Chip>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Box ref={this.containerRef} sx={{ background: bg, maxHeight: "calc(100% - 56px - 40px)", overflow }}>
                  {
                    isLoading ? <Box sx={{ px: 2 }}><SkeletonListAvatar rows={5} /></Box> :
                      (positions.length > 0 ?
                        <FixedSizeList height={listHeight}
                          itemCount={positions.length}
                          itemSize={80}
                          width={'100%'}>
                          {Row}
                        </FixedSizeList>
                        :
                        <Empty type={selected} />
                        // <Box sx={{ px: 2, display: "flex", alignItems: "center" }}>
                        //   <Box sx={{ flex: "40%" }}><img src="img/empty/MyPositionsEmpty.svg" /></Box>
                        //   <Box sx={{ flex: "60%", p: 1 }}>
                        //     <Typography color={'textSecondary'}>
                        //       {selected === 'op' ? <Trans components={{br: <br/>, i:<i></i>}}>my_mypos_op_empty_text</Trans>
                        //         : selected === 'cling' ? t("my_mypos_cling_empty_text")
                        //           : t("my_mypos_cl_empty_text")
                        //       }
                        //     </Typography>
                        //   </Box>
                        // </Box>
                      )}
                </Box>

              </Box>
            </Box>
          </DraggableCore>
        </Box>
      </div >
    );
  }
}

//export default MyAdvices;
export default withTheme(React.memo(MobMyPositions));

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', m: 1, py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyPositionsEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='subtitle2'><Trans><b>{t('my_mypos_empty_title')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('my_mypos_' + this.props.type + '_empty_text')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}