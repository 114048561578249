import { List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import DialogHelp from '../../elements/DialogHelp';

class MobDetailsWinseries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'trading/winseries/' + localStorage.getItem('ID') + '/' + this.props.id + '/theta2', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    return (
      <Box sx={{ mt: 2 }}>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={"DetailsWinseriesTitle"}
          helpDialogContent={<Trans
            components={{ ul: <List></List>, li: <ListItem></ListItem>, subtitle2: <Typography variant='subtitle2'></Typography> }}>
            {"DetailsWinseriesHelp"}
          </Trans>}
          okButton="ok" />

        <Typography variant="overline" component="h1"><Trans>{"DetailsWinseriesTitle"}</Trans></Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
            <Box><Typography variant="h4" className='big'>{data.last_series_count}</Typography></Box>
            <Box><Typography variant="body1"><Trans>winseries_Last</Trans></Typography></Box>
            <Box><Typography variant="caption" color="secondary">{data.last_series_start} - {data.last_series_end}</Typography></Box>
          </Box>
          <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
            <Box><Typography variant="h4" className='big'>{data.best_series_count}</Typography></Box>
            <Box><Typography variant="body1"><Trans>winseries_Best</Trans></Typography></Box>
            <Box><Typography variant="caption" color="secondary">{data.best_series_start} - {data.best_series_end}</Typography></Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default MobDetailsWinseries;
