import React, { Component } from 'react';
import { Box, Button, Container, Fade, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { withTheme } from '@emotion/react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import axios from 'axios';
import SkeletonList2 from '../elements/SkeletonList2';
import { ArrowRightAltOutlined } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { setMainTitle } from '../reduxActions/Actions';
import { connect } from 'react-redux';

class MyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.props.dispatch(setMainTitle("My Invoices"))
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'invoices/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        this.setState({ data, isLoading: false });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { data, isLoading } = this.state;
    return (
      <div>
        <BrowserView>
          <Fade in={true} timeout={450}>
            <div>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'><Trans>MyInvoicesTitle</Trans></Typography>
                    <Typography><Trans>inv_intro_text</Trans></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><Trans>invoices_date_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_description_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_method_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_subtotal_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_taxes_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_total_column_label</Trans></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            isLoading ? <Box sx={{ p: 3 }}><SkeletonList2 /></Box> :
                              data && data.length > 0 ? data.map((obj) => (
                                <TableRow>
                                  <TableCell>{obj.last_payment}</TableCell>
                                  <TableCell>{obj.product_name}</TableCell>
                                  <TableCell>{obj.payment_type}</TableCell>
                                  <TableCell>{obj.price}</TableCell>
                                  <TableCell>{obj.tax}</TableCell>
                                  <TableCell>{obj.paid_price}</TableCell>
                                  <TableCell><Button component={NavLink} to={'/invoice/' + obj.id_invoice} endIcon={<ArrowRightAltOutlined />}>View</Button></TableCell>
                                </TableRow>
                              )) : <TableRow>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </BrowserView>

        <MobileOnlyView>
          <Fade in={true} timeout={450}>
            <div>
              <Toolbar />
              <Container sx={{ p: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" className='big'><Trans>MyInvoicesTitle</Trans></Typography>
                    <Typography><Trans>inv_intro_text</Trans></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell><Trans>invoices_date_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_description_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_method_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_subtotal_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_taxes_column_label</Trans></TableCell>
                            <TableCell><Trans>invoices_total_column_label</Trans></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            isLoading ? <Box sx={{ p: 3 }}><SkeletonList2 /></Box> :
                              data && data.length > 0 ? data.map((obj) => (
                                <TableRow>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.last_payment}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.product_name}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.payment_type}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.price}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.tax}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}>{obj.paid_price}</TableCell>
                                  <TableCell sx={{whiteSpace: "nowrap"}}><Button component={NavLink} to={'/invoice/' + obj.id_invoice} endIcon={<ArrowRightAltOutlined />}>View</Button></TableCell>
                                </TableRow>
                              )) : <TableRow>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        </MobileOnlyView>
      </div>
    );
  }
}

export default withTheme(connect()(MyInvoices));