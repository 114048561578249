import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { IconButton, CardHeader } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import SkeletonChart from '../elements/SkeletonChart';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';

class MzFingerprint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      compareList: [],
      pfName: "",
      bmName: "",
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      chart_options: 0,
      chart_type: "",
      chart_height: "auto",
      chart_series: 0
    }
  }

  loadFingerprint(bm) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/portfolio_radar/' + this.props.id + '/' + bm, config)
      .then(response => {
        const data = response.data
        var series = [];
        var names = ["Max drawdown", "Return", "Sharpe", "Alpha", "VaR 95%", "Volatility"];
        for (var i = 0; i < data.length; i++) {
          series.push({
            'name': data[i].name,
            'data': [data[i].max_drawdown, data[i].ret, data[i].sharpe, data[i].alpha, data[i].var_95, data[i].volat]
          })
        }
        var options = {
          series: series,
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          dataLabels: { enabled: false },
          chart: {
            height: 'auto',
            type: 'radar',
            dropShadow: { enabled: false },
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            radar: {
              dataLabels: { enabled: false },
              polygons: {
                strokeColors: '#e6e6e6',
                strokeWidth: 1,
                connectorColors: '#f0f0f0',
              }
            }
          },
          title: {
            show: false
          },
          stroke: {
            width: 0
          },
          fill: {
            opacity: 0.5
          },
          markers: {
            size: 0
          },
          xaxis: {
            categories: names,
            labels: {
              style: {
                colors: ['#484848'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
              show: true
            },
          },
          yaxis: {
            show: false
          }
        };
        this.setState({ chart_options: options });
        this.setState({ chart_type: options.chart.type });
        this.setState({ chart_height: options.chart.height });
        this.setState({ chart_series: options.series });

      }).catch(error => {
        console.log(error);
      })

    let data2 = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/portfolios/', config)
      .then(response => {
        const compareList = response.data;
        this.setState({ compareList: compareList });
      }).catch(error => {
        console.log(error);
      })

  }

  handleChange(value) {
    this.setState({ bmv: value });
    this.loadFingerprint(value);
  }

  componentDidMount() {
    this.loadFingerprint('');
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data, pf, bm, compareList, bmv } = this.state;
    const { classes } = this.props;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzFingerprintTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzFingerprintHelp
            </Trans>
          }
        />

        <Card variant="outlined">
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzFingerprintTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            {this.state.chart_series ? (
              <ReactApexChart options={this.state.chart_options} series={this.state.chart_options.series} type={this.state.chart_type} height={350} ></ReactApexChart>
            ) :
              <SkeletonChart></SkeletonChart>
            }

          </CardContent>
          <CardActions>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="compare-label"><Trans>mz_fingerprint_label_compare</Trans></InputLabel>
              <Select
                labelId="compare-label"
                id="compare"
                value={bmv}
                onChange={(e) => this.handleChange(e.target.value)}
                label="Compare"
              >
                <MenuItem value="">
                  <em>-</em>
                </MenuItem>
                {compareList.map((obj) => (
                  obj.id != this.props.id &&
                  <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardActions>

        </Card>
      </>
    );
  }
}

export default withTheme(MzFingerprint);
