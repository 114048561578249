import { Card, CardContent, CardHeader, Toolbar } from "@mui/material";
import { Container } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaypalCheckout from "./PaypalCheckout";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const promise = loadStripe("pk_test_vXuZViKJbvXqvjwj5YC9ffF6");

export default function Paypal() {
  return (
    <Container sx={{ p: 2 }}>
      <Toolbar />
      <Card>
        <CardHeader></CardHeader>
        <CardContent sx={{ p: 4 }}>
            <PaypalCheckout />
        </CardContent>
      </Card>
    </Container>
  );
}