import React, { Component } from 'react';
import axios from 'axios';
import { Box, Card, CardActionArea, CardContent, CardHeader, Container, Fade, FormControl, FormHelperText, Grid, MenuItem, Select, Toolbar, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { SensorsOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import IntroMyPortfolios from '../intro/IntroMyPortfolios';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import MobIntroMyPortfolios from '../intro/MobIntroMyPortfolios';
import { connect } from 'react-redux';
import { setMainTitle } from '../reduxActions/Actions';
import numeral from 'numeral';
import ChartMini from '../elements/ChartMini';

class MyPortfolios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      visibleItems: [],
      introOpen: localStorage.getItem("ONB_MYPORTFOLIOS") ? false : true,
      loading: false,
      error: null,
      selectedCurrency: "€",
      selectedCapital: "250000",
      selectedStrategy: 0,
    }
  }

  selectPortfolio(id, name) {
    localStorage.setItem('MZPF', id);
    localStorage.setItem('MZPFN', name);
    window.location.href = '/portfolio'
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    console.log("mounting...")
    this.props.dispatch(setMainTitle("Portfolios"))

    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    console.log("calling...", process.env.REACT_APP_API + 'v2/myportfolios/portfolios')
    axios.get(process.env.REACT_APP_API + 'v2/myportfolios/portfolios', config)
      .then(response => {
        const data = response.data
        this.setState({ data, visibleItems: (data.length > 150 ? data.slice(0, 15) : data) });
      }).catch(error => {
        console.log(error);
      })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !this.state.loading) {
      this.showMoreItems();
    }
  };

  showMoreItems() {
    const { data, visibleItems } = this.state;
    const nextIndex = visibleItems.length + 5;
    const newVisibleItems = data.slice(0, nextIndex);
    this.setState({ visibleItems: newVisibleItems });
  }

  changeFilters(type, value) {
    switch (type) {
      case "CURR":
        this.setState({ selectedCurrency: value })
        break;
      case "CAPT":
        this.setState({ selectedCapital: value })
        break;
      case "STR":
        this.setState({ selectedStrategy: value })
        break;

      default:
        break;
    }
  }

  render() {
    const { data, visibleItems, introOpen, selectedCurrency, selectedCapital, selectedStrategy } = this.state;
    return (
      <Fade in="true" timeout={450}>
        <div>
          <BrowserView>
            <Toolbar />
            <Container sx={{ p: 2 }}>
              <IntroMyPortfolios
                open={introOpen}
                introClose={() => this.introClose()}
              />
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                <Typography variant="h4" className='big'>My Portfolios</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} sx={{ mt: 7 }}>

                <FormControl>
                  <FormHelperText sx={{ ml: 1 }}>Currency</FormHelperText>
                  <Select
                    value={selectedCurrency}
                    onChange={(e) => this.changeFilters("CURR", e.target.value)}
                    variant='outlined'
                    size='medium'
                    color='secondary'
                    sx={{ minWidth: 120, mr: 1 }}
                  >
                    {[
                      { 'l': <Trans>EUR</Trans>, 'v': '€' },
                      { 'l': <Trans>USD</Trans>, 'v': '$' },
                      { 'l': <Trans>GBP</Trans>, 'v': '£' },
                    ].map((item) => (
                      <MenuItem key={item.v} value={item.v}>
                        {item.l}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormHelperText sx={{ ml: 1 }}>Capital</FormHelperText>
                  <Select
                    value={selectedCapital}
                    onChange={(e) => this.changeFilters("CAPT", e.target.value)}
                    variant='outlined'
                    size='medium'
                    color='secondary'
                    sx={{ minWidth: 120, mr: 1 }}
                  >
                    {[
                      { 'l': <Trans>10000</Trans>, 'v': '10000' },
                      { 'l': <Trans>20000</Trans>, 'v': '20000' },
                      { 'l': <Trans>50000</Trans>, 'v': '50000' },
                      { 'l': <Trans>100000</Trans>, 'v': '100000' },
                      { 'l': <Trans>250000</Trans>, 'v': '250000' },
                    ].map((item) => (
                      <MenuItem key={item.v} value={item.v}>
                        {item.l}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormHelperText sx={{ ml: 1 }}>Strategy</FormHelperText>
                  <Select
                    value={selectedStrategy}
                    onChange={(e) => this.changeFilters("STR", e.target.value)}
                    variant='outlined'
                    size='medium'
                    color='secondary'
                    sx={{ minWidth: 120, mr: 1 }}
                  >
                    {[
                      { 'l': <Trans>Long only</Trans>, 'v': 'long' },
                      { 'l': <Trans>Long/Short</Trans>, 'v': 'short' },
                      { 'l': <Trans>All</Trans>, 'v': 0 },
                    ].map((item) => (
                      <MenuItem key={item.v} value={item.v}>
                        {item.l}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid container spacing={3} sx={{ mt: 2 }}>

                {data && data.length > 0 ? visibleItems.map((obj, i) => (
                  obj.ptf.currency === selectedCurrency
                    && obj.ptf.initial_capital === selectedCapital
                    && (obj.ptf.strategy === selectedStrategy || !selectedStrategy) ?
                    <Grid key={i} xs={12} md={6} lg={6} item>
                      <Fade in="true" timeout={450}>
                        <Card variant='outlined'>
                          <CardActionArea /*component={NavLink} to={"/portfolio/" + obj.ptf.id}*/>
                            <CardHeader
                              sx={{ pb: 0 }}
                              action=
                              {obj.ptf.is_rebalancing !== '0' ? <Tooltip title={"New signals today"}><SensorsOutlined color='success' /></Tooltip> : ''}

                              title={<Typography variant='body2' style={{ fontWeight: 500 }}>{obj.ptf.name}</Typography>}
                              subheader={<Typography variant='caption' color="textSecondary" style={{ fontWeight: 300 }}>{"Portfolio value: " + obj.ptf.currency + " " + numeral(obj.ptf.portfolioValue).format('0,000')}</Typography>}
                            />
                            <CardContent sx={{ pt: 0 }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                  {/* <Typography variant='h3' style={{ fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn).format('+0,000.00%')}</Typography> */}
                                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <Typography variant='h3' className={'big'}>{numeral(obj.ptf.totalReturn*100).format('+0,000.00')}</Typography>
                                    <Typography sx={{ ml: 0 }} variant='h5' className={'big'} >%</Typography>
                                  </Box>
                                  <Typography variant='caption'>Portfolio return</Typography>
                                  {/* <Divider style={{ margin: 8 }} component='p' variant='middle' />
                                  <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                      {(obj.ptf.totalReturn - obj.ptf.bReturn) >= 0 ?
                                        <Typography variant='h4' style={{ color: green[300], fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                        : <Typography variant='h4' style={{ color: red[300], fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                      }
                                      <Typography variant='caption' color='textSecondary'>Excess return</Typography>
                                    </Box>
                                  </Box> */}
                                </Box>
                                <Box sx={{ flex: '60%', px: 2 }}>
                                  <ChartMini isLoading={false} performances={obj.eql}></ChartMini>
                                </Box>
                              </Box>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Fade>
                    </Grid>
                    : ""
                )) : ''}

              </Grid>
            </Container>
          </BrowserView>

          <MobileOnlyView>
            <Toolbar />
            <Container sx={{ p: 2 }}>
              <MobIntroMyPortfolios
                open={introOpen}
                introClose={() => this.introClose()}
              />
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                <Typography variant="h4" className='big'>Portfolios</Typography>
              </Grid>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5" sx={{ mt: 4 }}><Trans>mz_overview_thematicTitle</Trans></Typography>
                  <Typography variant="body2"><Trans>mz_overview_thematic_desc</Trans></Typography>
                </Grid>
                {data.map((obj) => (
                  obj.based_on === 'thematic' ? (
                    <Grid item xs={6}>
                      <Card
                        row
                        key={obj.id}
                        variant="outlined"
                        className='fullHeightCard'
                      >
                        <CardActionArea component={Link} to={"/portfolio/" + obj.id} /*onClick={() => this.selectPortfolio(obj.id, obj.name)}*/ sx={{ display: 'flex', p: 1, height: '100%' }}>
                          <Box sx={{ flex: '40%', p: 1 }}>
                            <img width={'100%'} height='auto' src={"/img/mz/pfm_" + obj.type + ".svg"} alt={"portfolio " + obj.name} />
                          </Box>
                          <CardContent sx={{ flex: '60%', display: 'flex', flexDirection: 'column', p: 1 }}>
                            <Box sx={{ flex: "80%" }}><Typography variant='subtitle2'>{obj.name}</Typography></Box>
                            <Box sx={{ flex: "20%" }}><Typography variant='subtitle2' color='primary'>{obj.totalReturn}</Typography></Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : ''
                ))}

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5" sx={{ mt: 4 }}><Trans>mz_overview_indexTitle</Trans></Typography>
                  <Typography variant="body2"><Trans>mz_overview_index_desc</Trans></Typography>
                </Grid>
                {data.map((obj) => (
                  obj.based_on === 'index' ? (
                    <Grid item xs={6}>
                      <Card
                        row
                        key={obj.id}
                        variant="outlined"
                        className='fullHeightCard'
                      >
                        <CardActionArea component={Link} to={"/portfolio/" + obj.id} /*onClick={() => this.selectPortfolio(obj.id, obj.name)}*/ sx={{ display: 'flex', p: 1, height: '100%' }}>
                          <Box sx={{ flex: '40%', p: 1 }}>
                            <img src={"/img/mz/pfm_" + obj.type + ".svg"} alt={"portfolio " + obj.name} />
                          </Box>
                          <CardContent sx={{ flex: '60%', display: 'flex', flexDirection: 'column', p: 1 }}>
                            <Box sx={{ flex: "80%" }}><Typography variant='subtitle2'>{obj.name}</Typography></Box>
                            <Box sx={{ flex: "20%" }}><Typography variant='subtitle2' color='primary'>{obj.totalReturn}</Typography></Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : ''
                ))}
              </Grid>
            </Container>
          </MobileOnlyView>
        </div>
      </Fade>
    );
  }
}

export default connect()(MyPortfolios);