import React, { Component } from 'react';
import axios from 'axios';
import { BottomNavigation, BottomNavigationAction, Box, Card, CardActionArea, CardContent, CardHeader, Container, Fab, Fade, Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { Balance, ChevronLeft, ChevronRight, EnergySavingsLeaf, EnergySavingsLeafOutlined, HomeOutlined, ShowChart, ShowChartOutlined } from '@mui/icons-material';
import { Link, NavLink } from 'react-router-dom';
import IntroMyPortfolios from '../intro/IntroMyPortfolios';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import MobIntroMyPortfolios from '../intro/MobIntroMyPortfolios';
import { connect } from 'react-redux';
import { notifyLogout, setMainTitle } from '../reduxActions/Actions';

const swiper = useSwiper;

class PortfoliosOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      mobile_view: 1,
      introOpen: localStorage.getItem("ONB_MYPORTFOLIOS") ? false : true
    }
  }

  selectPortfolio(id, name) {
    localStorage.setItem('MZPF', id);
    localStorage.setItem('MZPFN', name);
    window.location.href = '/portfolio'
  }

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) {
      this.props.dispatch(notifyLogout())
    }

    this.props.dispatch(setMainTitle("Portfolios"))

    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'v2/mz/wa/profit', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  switchMobileView(mobile_view) {
    this.setState({ mobile_view });
  }

  render() {
    const { data, introOpen, mobile_view } = this.state;
    return (
      <Fade in="true" timeout={450}>
        <div>
          <BrowserView>
            <Toolbar />
            <Container sx={{ p: 2 }}>
              <IntroMyPortfolios
                open={introOpen}
                introClose={() => this.introClose()}
              />
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                <Typography variant="h4" className='big'>Portfolios</Typography>
              </Grid>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5" sx={{ mt: 4 }}><Trans>mz_overview_thematicTitle</Trans></Typography>
                  <Typography variant="body2"><Trans>mz_overview_thematic_desc</Trans></Typography>
                </Grid>
                <Grid item xs={12}>
                  {data && data.length > 0 ?
                    <Swiper
                      modules={[Navigation]}
                      navigation={{
                        prevEl: '#prev-thematic',
                        nextEl: '#next-thematic'
                      }}
                      spaceBetween={8}
                      slidesPerView={3}
                    >
                      <Fab size='small' color='primary' sx={{ position: 'absolute', left: 8, bottom: 8 }} id='prev-thematic' onClick={() => function () { swiper.slidePrev() }}><ChevronLeft /></Fab>
                      {data.map((obj) => (
                        obj.based_on === 'thematic' ? (
                          <SwiperSlide style={{ height: 'auto', padding: "4px 0" }}>
                            <Card
                              row
                              key={obj.id_portfolio}
                              variant="outlined"
                              sx={{ height: '100%' }}
                            >
                              <CardActionArea component={NavLink} to={"/portfolio/" + obj.id_portfolio} sx={{ height: '100%' }}>
                                <CardHeader
                                  sx={{ pb: 0 }}
                                  title={<Typography variant='subtitle2'>{obj.name}</Typography>}
                                  action={obj.is_reb && <Tooltip title={"Recently rebalanced"}><Balance color='buy' /></Tooltip>}
                                />
                                <CardContent sx={{ flex: '70%', display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ flex: '30%', textAlign: "center" }}>
                                    <img src={"/img/mz/pfm_" + obj.id_tag + ".svg"} alt={"portfolio " + obj.name} width={80} />
                                  </Box>
                                  <Box sx={{ flex: '70%', display: 'flex', flexDirection: 'column', p: 2 }}>
                                    <Typography variant='h6' color='primary' className='big'>{obj.ret_all}</Typography>
                                    <Typography variant='caption' sx={{fontWeight: 300}}><Trans>{"PortfoliosOverviewPtf" + obj.id_tag + "Desc"}</Trans></Typography>
                                  </Box>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </SwiperSlide>
                        ) : ''
                      ))}
                      <Fab variant='extended' size='small' color='primary' sx={{ position: 'absolute', right: 8, bottom: 8 }} id='next-thematic' onClick={() => function () { swiper.slideNext() }}><ChevronRight /></Fab>
                    </Swiper>
                    : 'loading...'}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h5" sx={{ mt: 4 }}><Trans>mz_overview_indexTitle</Trans></Typography>
                  <Typography variant="body2"><Trans>mz_overview_index_desc</Trans></Typography>
                </Grid>
                <Grid item xs={12}>
                  <Swiper
                    modules={[Navigation]}
                    navigation={{
                      prevEl: '#prev-index',
                      nextEl: '#next-index'
                    }}
                    spaceBetween={8}
                    slidesPerView={3}
                  >
                    <Fab size='small' color='primary' sx={{ position: 'absolute', left: 8, bottom: 8 }} id='prev-index' onClick={() => function () { swiper.slidePrev() }}><ChevronLeft /></Fab>
                    {data.map((obj) => (
                      obj.based_on === 'index' ? (
                        <SwiperSlide style={{ height: 'auto', padding: "4px 0" }}>
                            <Card
                              row
                              key={obj.id_portfolio}
                              variant="outlined"
                              sx={{ height: '100%' }}
                            >
                              <CardActionArea component={NavLink} to={"/portfolio/" + obj.id_portfolio} sx={{ height: '100%' }}>
                                <CardHeader
                                  sx={{ pb: 0 }}
                                  title={<Typography variant='subtitle2'>{obj.name}</Typography>}
                                  action={obj.is_reb && <Tooltip title={"Recently rebalanced"}><Balance color='buy' /></Tooltip>}
                                />
                                <CardContent sx={{ flex: '70%', display: 'flex', alignItems: 'center' }}>
                                  <Box sx={{ flex: '30%', textAlign: "center" }}>
                                    <img src={"/img/mz/pfm_" + obj.id_tag + ".svg"} alt={"portfolio " + obj.name} width={80} />
                                  </Box>
                                  <Box sx={{ flex: '70%', display: 'flex', flexDirection: 'column', p: 2 }}>
                                    <Typography variant='h6' color='primary' className='big'>{obj.ret_all}</Typography>
                                    <Typography variant='caption' sx={{fontWeight: 300}}><Trans>{"PortfoliosOverviewPtf" + obj.id_tag + "Desc"}</Trans></Typography>
                                  </Box>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                        </SwiperSlide>
                      ) : ''
                    ))}
                    <Fab size='small' color='primary' sx={{ position: 'absolute', right: 8, bottom: 8 }} id='next-index' onClick={() => function () { swiper.slideNext() }}><ChevronRight /></Fab>
                  </Swiper>
                </Grid>
              </Grid>
            </Container>
          </BrowserView>

          <MobileOnlyView>
            <Toolbar />
            <Container sx={{ p: 2 }}>
              <MobIntroMyPortfolios
                open={introOpen}
                introClose={() => this.introClose()}
              />
              {/* <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                <Typography variant="h4" className='big'>Portfolios</Typography>
              </Grid> */}
              <Grid container spacing={2} sx={{ pb: 7 }}>
                {mobile_view === 1 ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography gutterBottom variant="h4" className='big'><Trans>mz_overview_thematicTitle</Trans></Typography>
                      <Typography variant="body2" sx={{ fontWeight: 300, mb: 2 }} color="textSecondary"><Trans>mz_overview_thematic_desc</Trans></Typography>
                    </Grid>
                    {data.map((obj) => (
                      obj.based_on === 'thematic' ? (
                        <Grid item xs={6}>
                          <Card
                            row
                            key={obj.id_portfolio}
                            variant="outlined"
                            className='fullHeightCard'
                          >
                            <CardActionArea component={Link} to={"/portfolio/" + obj.id_portfolio} sx={{ height: '100%' }}>
                              <CardContent sx={{ display: 'flex', flexDirection: 'column', py: "8px!important", px: 2 }}>
                                <Box sx={{}}><Typography variant='body2' sx={{ whiteSpace: 'nowrap', maxWidth: '100%', overflow: "hidden", textOverflow: "ellipsis" }}><b>{obj.name}</b></Typography></Box>
                                <Box sx={{ p: 2, flex: 1 }}>
                                  <Box sx={{ textAlign: 'center' }}><img style={{ height: 90, maxWidth: '100%' }} src={"/img/mz/pfm_" + obj.id_tag + ".svg"} alt={"portfolio " + obj.name} /></Box>
                                </Box>
                                <Box sx={{ height: 35, textAlign: 'center' }}><Typography variant='h5' className='big'>{obj.ret_all}</Typography></Box>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ) : ''
                    ))}
                  </>
                ) : mobile_view === 2 ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography gutterBottom variant="h4" className='big'><Trans>mz_overview_indexTitle</Trans></Typography>
                      <Typography variant="body2" sx={{ fontWeight: 300, mb: 2 }} color="textSecondary"><Trans>mz_overview_index_desc</Trans></Typography>
                    </Grid>
                    {data.map((obj) => (
                      obj.based_on === 'index' ? (
                        <Grid item xs={6} key={obj.id_portfolio}>
                          <Card
                            row
                            key={obj.id_portfolio}
                            variant="outlined"
                          >
                            <CardActionArea component={Link} to={"/portfolio/" + obj.id_portfolio} /*onClick={() => this.selectPortfolio(obj.id_portfolio, obj.name)}*/
                              sx={{ height: '100%' }}>
                              <CardContent sx={{ display: 'flex', flexDirection: 'column', py: "8px!important", px: 2 }}>
                                <Box sx={{}}><Typography variant='body2' sx={{ whiteSpace: 'nowrap', maxWidth: '100%', overflow: "hidden", textOverflow: "ellipsis" }}><b>{obj.name}</b></Typography></Box>
                                <Box sx={{ p: 2, flex: 1 }}>
                                  <Box sx={{ textAlign: 'center' }}><img style={{ height: 90, maxWidth: '100%' }} src={"/img/mz/pfm_" + obj.id_tag + ".svg"} alt={"portfolio " + obj.name} /></Box>
                                </Box>
                                <Box sx={{ height: 35, textAlign: 'center' }}><Typography variant='h5' className='big'>{obj.ret_all}</Typography></Box>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ) : ''
                    ))}
                  </>
                ) : 'No view selected'}
              </Grid>
            </Container>
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
              <BottomNavigation
                value={mobile_view}
                onChange={(event, newValue) => {
                  this.switchMobileView(newValue);
                }}
                showLabels

              >
                <BottomNavigationAction component={NavLink} to="/home" label="Home" value="0" icon={<HomeOutlined />} />
                {[
                  { 'l': 'Themes', 'v': 1, iconOn: <EnergySavingsLeaf />, iconOff: <EnergySavingsLeafOutlined /> },
                  { 'l': 'Indices', 'v': 2, iconOn: <ShowChart />, iconOff: <ShowChartOutlined /> },
                ].map((item) => {
                  const checked = mobile_view === item.v;
                  return (
                    <BottomNavigationAction label={item.l} value={item.v} icon={checked ? item.iconOn : item.iconOff} />
                  )
                })
                }
              </BottomNavigation>
            </Box>
          </MobileOnlyView>
        </div>
      </Fade>
    );
  }
}

export default connect()(PortfoliosOverview);