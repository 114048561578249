import { withTheme } from '@emotion/react';
import { Check, CircleOutlined } from '@mui/icons-material';
import { Alert, Avatar, Box, Button, Collapse, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import BrainoSnackbar from '../commons/BrainoSnackbar';
import I18n, { getFlag } from '../commons/Utils';
import i18n from '../commons/i18n';
import DialogHelp from '../elements/DialogHelp';

class SettingsMainMob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
      detailsOpen: false,
      helpTitle: '',
      helpText: '',
      helpTitle: <Trans> {this.props.prefix} </Trans>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      lang: localStorage.getItem('LANG'),
      notify1: false,
      notify2: false,
      isLight: true,
      open: false,

      snackOpen: false,
      snackMessage: ''
    }
  }

  closeDetailsDialog() {
    this.setState({ detailsOpen: false })
  }

  openDetailsDialog() {
    this.setState({ detailsOpen: true })
  }

  async componentDidMount() {
    this.loadNotifications1()
    this.loadNotifications2()

    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'profiles/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }

  async changeLangServer(value) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.put(process.env.REACT_APP_API + 'lang/' + localStorage.getItem('ID'), 'lang=' + value, config)
      .then(response => {
        localStorage.setItem('LANG', value);
        this.setState({ snackOpen: true, snackMessage: 'Language changed' })
      }).catch(error => {
        console.log(error);
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  changeLang(value) {
    if (value !== i18n.language) {
      i18n.changeLanguage(value)
      i18n.reloadResources();
      this.setState({ lang: value })
      this.changeLangServer(value)
    }
  }

  loadNotifications1() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        let accept = data === "1" ? 1 : 0
        this.setState({ notify1: accept, open: accept ? false : true })
      }).catch(error => {
        console.log(error);
      })
  }

  loadNotifications2() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'social/newsletter2_accept/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        let accept = data === "1" ? 1 : 0
        this.setState({ notify2: accept })
      }).catch(error => {
        console.log(error);
      })
  }

  changeNotifications1(e) {
    let value = e.target.checked
    value = value ? 1 : 0
    this.setState({ notify1: value })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.put(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID') + '/' + value, null, config)
      .then(response => {
        this.setState({ open: !value })
      }).catch(error => {
        console.log(error);
      })
  }

  changeNotifications2(value) {
    value = value ? 1 : 0
    this.setState({ notify2: value })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.put(process.env.REACT_APP_API + 'social/newsletter2_accept/' + localStorage.getItem('ID') + '/' + value, null, config)
      .then(response => {

      }).catch(error => {
        console.log(error);
      })
  }

  render() {
    const { lang, notify1, notify2, isLight, snackOpen, snackMessage, open } = this.state;
    return (
      <Box>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={this.state.helpTitle}
          helpDialogContent={this.state.helpText}
          okButton="ok" />

        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <Typography gutterBottom variant="overline" component="h2">{t("settings_pageTitle")}</Typography>
        <Box>
          <Box sx={{ py: 2 }}>
            <Box><Typography gutterBottom variant='subtitle2'><b><Trans>lang_checkboxTitle</Trans></b></Typography></Box>
            <Box sx={{ mb: 3 }}>
              <Button size='large' disableElevation startIcon={<Avatar variant='square' sx={{ width: 22, height: 18 }} src={getFlag("Italy")} />} sx={{ mr: 1 }} variant={lang === 'it' ? 'contained' : 'outlined'} onClick={() => this.changeLang('it')}>Italiano</Button>
              <Button size='large' disableElevation startIcon={<Avatar variant='square' sx={{ width: 22, height: 18 }} src={getFlag("UnitedKingdom")} />} sx={{ mr: 1 }} variant={lang === 'en' ? 'contained' : 'outlined'} onClick={() => this.changeLang('en')}>English</Button>
            </Box>
            <Typography gutterBottom variant='subtitle2'><b><Trans>notifications_checkboxTitle</Trans></b></Typography>
            <Box sx={{ display: 'flex', mb: 3, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: "center" }}>
                <Box sx={{ flex: 1 }}>{t("mail_marketing_checkboxTitle")}</Box>
                <Box sx={{ width: 80, display: 'flex', justifyContent: "center" }}> <Switch checked={notify1} onChange={(e) => this.changeNotifications1(e)} inputProps={{ 'aria-label': 'controlled' }} /></Box>
              </Box>
              <Collapse timeout={150} in={open}>
                <Alert severity="warning" color='error' sx={{ pb: 2 }}>
                  <Trans>reg_alert_msg</Trans>
                </Alert>
              </Collapse>
            </Box>
            <Box sx={{ display: 'flex', mb: 3, alignItems: "center" }}>
              <Box sx={{ flex: 1 }}>{t("mail_preferences_checkboxTitle")}</Box>
              <Box sx={{ width: 80, display: 'flex', justifyContent: "center" }}> <Switch checked={notify2} onChange={(e) => this.changeNotifications2(e.target.checked)} /></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withTheme(SettingsMainMob);
