import React, { Component } from 'react';
import { Typography, Chip, Button, Dialog, DialogContent, TextField, InputAdornment, Box, Grid } from '@mui/material';
import axios from 'axios';
import { EditOutlined } from '@mui/icons-material';
import DialogHelp from '../../elements/DialogHelp';
import numeral from 'numeral';
import SkeletonChart from '../../elements/SkeletonChart';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { useMobileOrientation } from 'react-device-detect';

class MobMyStatsEquityLineBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingBackround: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      chart_options: 0,
      chart_type: "",
      chart_height: "auto",
      chart_series: 0,
      disabled: true,
      amountDialogOpen: false,
      amount: 0,
      isLandscape: false,

      selected: 10000
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ isLandscape: this.props.isLandscape })
      console.log("YEP>>", this.props.isLandscape)
    }
    //console.log("NOT>>", this.props.isLandscape)
  }

  componentDidMount() {
    this.loadChart()
  }

  loadChart(value) {
    let selected = this.state.selected
    value = value ? value : selected
    this.setState({ selected: value })
    if (!value) this.setState({ isLoading: true })
    else this.setState({ isLoadingBackround: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'v2/myst/wa/eqline/' + value + '/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        var item, series = [];
        for (var i = 0; i < data.length; i++) {
          item = data[i];
          var quote = {};
          quote.x = item.exit_date//moment(item.exit_date).format("MMM YYYY");
          quote.y = item.eq;
          series.push(quote);
        }
        var series_data = [
          {
            name: t('myst_eqline_chart_label'),
            data: series
          }
        ];

        var options = {
          series: series_data,
          chart: {
            type: 'area',
            height: "100%",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: ['#8D21E9'],
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          grid: {
            borderColor: '#e6e6e6',
            yaxis: {
              lines: {
                show: true,
              }
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2.5,
            curve: 'smooth',
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 5
            }
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0,000.0[0]') + '&euro;';
              }
            }
          },
          yaxis: {
            min: function (min) { return min },
            tickAmount: 3,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0,000.[00]') },
              style: {
                colors: ['#484848'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: ['#484848'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
        };
        this.setState({ isLoading: false, data, chart_options: options, chart_type: options.chart.type, chart_height: options.chart.height, chart_series: series_data, amount: localStorage.getItem('INVUNIT') });

      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(()=>{
        this.setState({ isLoading: false, isLoadingBackround: false });
      })
  }

  changeValue() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.put(process.env.REACT_APP_API + 'users/settings/investment_unit', 'name=investment_unit&value=' + parseFloat(this.state.amount), config)
      .then(response => {
        const data = response.data
        localStorage.setItem('INVUNIT', parseFloat(this.state.amount));
        this.setState({ disabled: 1, amountDialogOpen: false });
        this.loadChart()
      }).catch(error => {
        console.log(error);
      }).finally(()=>{
        this.setState({ isLoading: false, isLoadingBackround: false });
      })
  }

  handleAmountDialog() {
    this.setState(prevState => ({
      amountDialogOpen: !prevState.amountDialogOpen
    }));
  }


  enableButton(amount) {
    this.setState({ disabled: 0, amount })
  }

  handleCloseBig() {

  }

  render() {
    const { data, selected, disabled, amountDialogOpen, amount, isLoading, isLandscape, isLoadingBackround } = this.state;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsEquityLineTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsEquityLineHelp
            </Trans>
          }
        />

        <Dialog open={amountDialogOpen} closeBigDialog={() => this.handleAmountDialog()}>
          <DialogContent>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label='Euro'
                defaultValue={amount}
                size='medium'
                onKeyUp={(e) => this.enableButton(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditOutlined />
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: 2 }} />
              <Button color="primary" disabled={disabled ? 1 : 0} onClick={() => this.changeValue()}>Apply</Button>
            </Box>
          </DialogContent>
        </Dialog>

        <MobMyStatsEquityLineBig open={isLandscape} onClose={() => this.handleCloseBig()}
          options={this.state.chart_options}
          series={this.state.chart_series}
          height={this.state.chart_height}
          type={this.state.chart_type} />

        <Box sx={{ mb: 3, display: 'flex', flex: 1, flexDirection: "column" }}>
          <Typography gutterBottom variant="overline" component="h2"><b><Trans>{"MyStatsEquityLineTitle"}</Trans></b></Typography>
          <Box sx={{ mb: 2, flex: 1, position: "relative" }}>
            {
              isLoading ? <SkeletonChart height={267} /> :
                data.length > 0 ? (
                  <>
                    <Typography variant='caption' color={'primary'}>
                      <Trans
                        i18nKey='myst_eqline_chartTitle'
                        values={{ amount }}
                        components={{
                          linkToPopup_amount: <Button sx={{ ml: 3 }} variant='outlined' size='small' onClick={() => this.handleAmountDialog()}></Button>
                        }} />
                    </Typography>
                    <ReactApexChart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type} />
                  </>
                ) : <Empty img={this.constructor.name} />
            }
          </Box>
          <Box sx={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'center', pt: 2 }}>
            <Grid container maxWidth={300} spacing={1}>
              {[
                { 'l': <Trans>myst_eqline_all_btn</Trans>, 'v': 10000 },
                { 'l': <Trans>myst_eqline_12m_btn</Trans>, 'v': 12 },
                { 'l': <Trans>myst_eqline_6m_btn</Trans>, 'v': 6 },
                { 'l': <Trans>myst_eqline_3m_btn</Trans>, 'v': 3 },
              ].map((item) => {
                const checked = selected === item.v;
                return (
                  <Grid item xs={6}>
                    <Chip
                      sx={{ width: "100%", }}
                      size='medium'
                      key={item.l}
                      variant={checked ? 'plain' : 'outlined'}
                      color={checked ? 'primary' : 'secondary'}
                      label={checked && isLoadingBackround ? "Loading..." : item.l}
                      onClick={() => {
                        this.loadChart(item.v);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

class MobMyStatsEquityLineBig extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <>
        <Dialog open={this.props.open} onClose={this.props.closeBigDialog} fullScreen>
          <DialogContent>
            <ReactApexChart
              options={this.props.options}
              series={this.props.series}
              height={this.props.height}
              type={this.props.type} />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%' }}><img src={'/img/empty/MyStatsEquityLineEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans><b>{t('myst_eqline_emptyTitle')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_eqline_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 2 }}><Button variant='contained' disableElevation component={NavLink} to='/mybraino'><Trans>{t('myst_eqline_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}

export default function MobMyStatsEquityLine() {
  const { isLandscape } = useMobileOrientation()// extract navigation prop here 

  console.log(isLandscape)
  let ori = isLandscape
  return <MobMyStatsEquityLineBase isLandscape={ori} /> //pass to your component.

}