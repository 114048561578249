import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IconButton, CardHeader, FormControlLabel, Switch, Container } from '@mui/material';
import { withStyles } from "@mui/material";
import axios from 'axios';
import numeral from 'numeral';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material'
import SkeletonChart from '../elements/SkeletonChart';
import DialogHelp from '../elements/DialogHelp';
import I18n from '../commons/Utils';
import Chart from '../elements/Chart';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';

const styles = theme => ({
  maxDrawdown: {
    textAlign: 'right',
    fontFamily: 'Fjalla One',
  }, maxDrawdownLabel: {
    textAlign: 'right',
  }
});

class MzDrawdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      withBenchmark: true,
      maxDrawdown: 0,
      pfName: "",
      bmName: "",
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      chart_options: 0,
      chart_type: "",
      chart_height: "auto",
      chart_series: 0
    }
  }

  handleChange = (e) => {
    this.setState({ withBenchmark: e.target.checked });
    this.getChart(e.target.checked);
  };

  getMaxDd() {
    this.setState({ data: [] });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/max_drawdown_value/' + this.props.id, config)
      .then(response => {
        const data = response.data
        this.setState({ maxDrawdown: data.max_drawdown });
      }).catch(error => {
        console.log(error);
      })
  }

  getChart(withBenchmark) {
    this.setState({ data: [] });
    let uri = withBenchmark ? 'drawdown_benchmark' : 'drawdown';
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/' + uri + '/' + this.props.id, config)
      .then(response => {
        const data = response.data
        var series = withBenchmark ? data.series : response.data;
        var names = withBenchmark ? data.names : 0;
        var p_prices = [];
        var b_prices = [];
        if (withBenchmark) {
          for (var i = 0; i < series.length; i += 10) {
            p_prices.push({ 'x': series[i].d, 'y': series[i].pv })
            b_prices.push({ 'x': series[i].d, 'y': series[i].bv })
          }
          var series_data = [
            {
              name: names.p,
              data: p_prices
            }, {
              name: names.b,
              data: b_prices
            }
          ];
        } else {
          for (var i = 0; i < series.length; i += 10) {
            p_prices.push({ 'x': series[i].date, 'y': series[i].value })
          }
          var series_data = [
            {
              name: names.p,
              data: p_prices
            }
          ];
        }

        var options = {
          series: series_data,
          chart: {
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            width: '100%',
            height: 250,
            type: "line",
            stacked: false
          },
          legend: {
            itemMargin: {
              vertical: 16
            },
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
          },
          animations: {
            enabled: true,
            easing: 'easein',
            speed: 250,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          stroke: {
            width: [1.5, 1]
          },
          grid: {
            borderColor: '#e6e6e6',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            max: 0,
            min: function (min) { return min },
            tickAmount: 4,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]') },
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0.0[00]');
              }
            }
          },
        };
        this.setState({ chart_options: options });
        this.setState({ chart_type: options.chart.type });
        this.setState({ chart_height: options.chart.height });
        this.setState({
          chart_series: series_data
        });



      }).catch(error => {
        console.log(error);
      })
  }

  async componentDidMount() {
    this.getChart(true);
    this.getMaxDd();
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data, withBenchmark } = this.state;
    const { classes } = this.props;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MzDrawdownTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MzDrawdownHelp
            </Trans>
          }
        />

        <Card variant="outlined" >
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MzDrawdownTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent>
            <Typography className='big' variant="h4" component="h2">
              {this.state.maxDrawdown}
            </Typography>
            <Typography gutterBottom variant="caption" component="p" color='secondary'>
              <Trans>mz_drawdown_label_maxDrawdown</Trans>
            </Typography>

            <Container width="100%" height={300}>
              {this.state.chart_series ? (
                withBenchmark ? (
                  <Chart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type}></Chart>
                ) : (
                  <Chart options={this.state.chart_options} series={this.state.chart_series} height={this.state.chart_height} type={this.state.chart_type}></Chart>
                )
              ) : <SkeletonChart></SkeletonChart>
              }
            </Container>
          </CardContent>
          <CardActions>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.withBenchmark}
                  onChange={this.handleChange.bind(this)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Trans>mz_drawdown_button</Trans>}
            />
          </CardActions>
        </Card >
      </>
    );
  }
}

export default withTheme(MzDrawdown);