import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import SkeletonChart from '../../elements/SkeletonChart';
import DialogHelp from '../../elements/DialogHelp';
import I18n from '../../commons/Utils';
import numeral from 'numeral';
import ReactApexChart from 'react-apexcharts';
import { withTheme } from '@emotion/react';

class MobMzEquityLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pfName: "",
      bmName: "",
      helpTitle: <I18n prefix={this.props.prefix} type="title"></I18n>,
      helpText: <I18n prefix={this.props.prefix} type="help"></I18n>,
      chart_options: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }

  async componentDidMount() {
    var chart_options = {
      series: [],
      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        width: '100%',
        height: '100%',
        type: "line",
        stacked: false
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        itemMargin: {
          vertical: 16
        },
      },
      animations: {
        enabled: true,
        easing: 'easein',
        speed: 250,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
      stroke: {
        width: [2, 1],
        curve: 'smooth',
      },
      grid: {
        borderColor: this.props.theme.palette.grid,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          rotate: 0,
          style: {
            colors: this.props.theme.palette.text.primary,
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        min: function (min) { return min },
        tickAmount: 3,
        forceNiceScale: false,
        labels: {
          show: true,
          formatter: (value) => { return numeral(value).format('0.[00]') },
          style: {
            colors: '#848484',
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      tooltip: {
        shared: false,
        x: { show: false },
        y: {
          formatter: function (val) {
            return numeral(val).format('0.0[00]');
          }
        }
      },
    };
    this.setState({ chart_options })

    this.loadChart()
  }

  loadChart() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'v2/mz/wa/equity_line_benchmark/' + this.props.id, config)
      .then(response => {
        const data = response.data
        var series = data.s
        var names = data.n
        var p_prices = [];
        var b_prices = [];
        for (var i = 0; i < series.length; i += 10) {
          var d = series[i].d
          p_prices.push({ 'x': d, 'y': series[i].pv * 1 })
          b_prices.push({ 'x': d, 'y': series[i].bv * 1 })
        }

        var series_data = [
          {
            name: names.p,
            type: 'line',
            data: p_prices
          },
          {
            name: names.b,
            type: 'line',
            data: b_prices
          }
        ];

        let options = this.state.chart_options;
        options.series = series_data;
        this.setState({ chart_options: options })

      }).catch(error => {
        console.log('NO CHART', error);
        this.state.chart_options = 0
      })
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { chart_options } = this.state;
    return (
      <Box sx={{px: 2, height: "100%"}}>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={this.state.helpTitle}
          helpDialogContent={this.state.helpText}
          okButton="ok" />
        {/* <Typography gutterBottom variant="body1" component="h3"><b>Equity line</b></Typography> */}
        {chart_options ? (
          <ReactApexChart series={chart_options.series} type={chart_options.chart.type} options={chart_options} height={chart_options.chart.height}></ReactApexChart>
        ) : <SkeletonChart></SkeletonChart>}
      </Box>
    );
  }
}

export default withTheme(MobMzEquityLine);