import React from 'react';
import { Typography, DialogContent, DialogTitle, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import { Component } from 'react';
import SkeletonList from '../elements/SkeletonListAvatar';
import axios from 'axios';
import { ExpandMoreOutlined as ExpandMoreOutlinedIcon, Check as CheckOutlinedIcon } from '@mui/icons-material'
import I18n from '../commons/Utils';
import { Trans } from 'react-i18next';

class MzRiskDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      expanded: false
    }
  }

  handleChange(panel) {
    this.setState({ expanded: panel === this.state.expanded ? false : panel });
  };

  componentDidMount() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/risk_details/' + this.props.id, config)
      .then(response => {
        const data = response.data
        this.setState({ data: data });
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
    this.setState({ isLoading: false });
  }

  render() {
    const { data, expanded } = this.state;
    const { classes } = this.props;

    return (
      <Dialog id={this.props.id} open={this.props.isDialogOpened} onClose={this.props.closeDialog}>
        <DialogTitle>{<I18n prefix={this.props.prefix} type="title"></I18n>}</DialogTitle>
        <DialogContent>
          {data ? Object.keys(data).map((d, key) => (
            <Accordion sx={{border:'0!important', my:1, borderRadius:0}} expanded={expanded === 'panel' + key} onChange={() => this.handleChange('panel' + key)}>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlinedIcon />}
                aria-controls={'panel' + key + 'bh-content'}
                id={'panel' + key + 'bh-header'}
              >
                <Box sx={{display: 'inline-flex', alignItems: 'center', width:'100%'}}>
                <Typography variant='subtitle1' sx={{flex:'80%', flexGrow:1}}>
                  <Trans>{this.props.prefix + "_label_" + d}</Trans>
                </Typography>
                <Typography variant='subtitle2' sx={{flex:'20%', mr:4, textAlign:'right'}} component='div'>{data[d]}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body2'>
                <Trans components={{
                  em: <em></em>,
                  sub: <sub></sub>,
                  overline: <overline></overline>,
                  img_sharpe: <img height={30} src="/img/formulas/mz_sharpe.png"/>,
                  img_sortino: <img height={30} src="/img/formulas/mz_sortino.png"/>,
                  img_volatility: <img width={200} src="/img/formulas/mz_volatility.png"/>,
                }}>{this.props.prefix + "_desc_" + d}</Trans>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )) : data.length === 0 ? ("not allowed") :
            <SkeletonList></SkeletonList>
          }
        </DialogContent>
        <DialogActions>
          <Button variant='' onClick={this.props.closeDialog} color="primary">
            <CheckOutlinedIcon color="primary"></CheckOutlinedIcon>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MzRiskDetails;