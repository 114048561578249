import React, { Component } from 'react';
import { Dialog, DialogActions, Button, Typography, DialogContent, DialogTitle, Box, Slide, Avatar } from '@mui/material';
import { getFlag } from "../commons/Utils";
import ReactApexChart from "react-apexcharts";
import { FlagOutlined, SportsScoreOutlined, VerticalAlignBottom, VerticalAlignTop } from "@mui/icons-material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import SkeletonChart from "./SkeletonChart";
import numeral from "numeral";
import axios from 'axios';
import moment from 'moment';
import { COLORS } from '../commons/Colors';
import SkeletonListAvatar from './SkeletonListAvatar';
import { withTheme } from '@emotion/react';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogTradeMob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoading2: false,
      imgLoaded: false,
      imgError: false,
      data: [],

      max_min_items: [],
      logo: null,
    }
  }

  getSnapshot = () => {
    this.setState({ isLoading2: true, imgLoaded: false });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'snapshot/' + localStorage.getItem('ID') + '/' + this.props.idInstr, config)
      .then(response => {
        const data = response.data
        this.setState({ logo: data.logo });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading2: false });
      })
  }

  loadPosition = () => {
    this.setState({ isLoading: true });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'mypos/' + localStorage.getItem('ID') + '/' + this.props.idTrade + '/theta2', config)
      .then(response => {
        const data = response.data

        let hist = data.hist;
        let info_data = data.info_data;
        let chart_prices = [];
        var chart_date_open = 0, chart_date_exit = 0, chart_date_split = 0, chart_price_split = 0;
        var max = info_data.open_price ? info_data.open_price : -999999;
        var max_d = 0;
        var min = info_data.open_price ? info_data.open_price : 999999;;
        var min_d = 0;
        var max_min_items = [];
        for (var i = 0; i < hist.hist.length; i++) {
          chart_prices.push({ 'x': hist.hist[i].d, 'y': hist.hist[i].c });
          if (hist.hist[i].c > max) {
            max = hist.hist[i].c;
            max_d = hist.hist[i].d;
          } else if (hist.hist[i].c < min) {
            min = hist.hist[i].c;
            min_d = hist.hist[i].d;
          }

          if (hist.dates.open_date === hist.hist[i].d) {
            chart_date_open = hist.hist[i].d;
          } else if (hist.dates.exit_date === hist.hist[i].d) {
            chart_date_exit = hist.hist[i].d;
          } else if (hist.dates.split_date === hist.hist[i].d) {
            chart_date_split = hist.hist[i].d;
            chart_price_split = hist.hist[i].c;
          }
        }
        max_min_items = [
          {
            p: numeral(min).format('0.00[0]'),
            d: min_d ? moment(min_d, "YYYYMMDD").format("DD/MM/YYYY") : info_data.open_date,
            l: <VerticalAlignBottom />,
            desc: 'Min'
          },
          {
            p: numeral(max).format('0.00[0]'),
            d: max_d ? moment(max_d, "YYYYMMDD").format("DD/MM/YYYY") : info_data.open_date,
            l: <VerticalAlignTop />,
            desc: 'Max'
          }
        ]
        if (max_d < min_d) {
          max_min_items.reverse();
        }
        let percGainValue = info_data.perc_gain_value
        let chartColor = percGainValue > 0 ? COLORS.green : COLORS.red
        var options = {
          series: [
            {
              name: t('my_chart_label'),
              data: chart_prices
            }
          ],
          chart: {
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            height: 200,
            type: "area",
            stacked: false,
            animations: {
              enabled: true,
              easing: 'easeout',
              speed: 550,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: false,
                speed: 350
              }
            },
          },

          dataLabels: {
            enabled: false
          },
          colors: [chartColor],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: [chartColor, COLORS.white],
              opacityFrom: 1,
              opacityTo: .1,
              stops: [5]
            }
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          stroke: {
            width: [2]
          },
          annotations: {
            points: [{
              x: moment(chart_date_open).format("DD MMM YYYY"),
              y: info_data.open_price,
              marker: {
                size: 3,
                fillColor: COLORS.white,
                strokeColor: COLORS.black,
                radius: 1,
              },
              label: {
                borderColor: 'transparent',
                fill: 'transparent',
                offsetY: 0,
                style: {
                  background: 'transparent',
                },

                text: info_data.open_signal,
              }
            }, {
              x: moment(chart_date_exit).format("DD MMM YYYY"),
              y: info_data.exit_price,
              marker: {
                size: 3,
                fillColor: COLORS.white,
                strokeColor: COLORS.black,
                radius: 1,
              },
              label: {
                borderColor: 'transparent',
                fill: 'transparent',
                offsetY: 0,
                style: {
                  background: 'transparent',
                },

                text: 'E',
              }
            }, {
              x: moment(chart_date_split).format("DD MMM YYYY"),
              y: chart_price_split,
              marker: {
                size: 4,
                fillColor: COLORS.white,
                strokeColor: '#ff9400',
                radius: 2,
              },
              label: {
                borderColor: 'transparent',
                fillColor: 'transparent',
                offsetY: 0,
                style: {
                  background: 'transparent',
                },

                text: 'S',
              }
            },],
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            min: function (min) { return min },
            tickAmount: 3,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.0[0]') },
              style: {
                //colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          tooltip: {
            shared: false,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0.0[00]');
              }
            }
          },
          legend: {
            show: false,
          },
        };

        this.setState({
          posDialogTitle: data.info_data.name
          , posDialogHist: data.hist.hist
          , posDialogData: data.info_data
          , isLoading: false
          , options
          , max_min_items
        });

      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      })
    this.setState({ posDialogOpen: true });
  }

  componentDidMount() {
    this.setState({ imgLoaded: false, imgError: false, logo: null, data: [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ imgLoaded: false, imgError: false, logo: null, data: [] });
      this.getSnapshot()
      this.loadPosition()
    }
  }

  handleImageLoaded = (e) => {
    if (!this.state.imgError) {
      console.log("loaded")
      this.setState({ imgLoaded: true })
    }
  }
  handleImageError = (e) => {
    e.preventDefault()
    e.stopPropagation()
    console.log("failed")
    this.setState({ imgLoaded: false, imgError: true })
  }

  render() {
    const { isLoading, imgLoaded, posDialogTitle, posDialogData, options, posDialogHist, max_min_items, logo } = this.state
    const props = this.props
    return (
      <div>
        <Dialog scroll={"paper"} keepMounted={false} fullScreen open={props.open} onBackdropClick={props.closePos} TransitionComponent={Transition}>
          <DialogTitle sx={{ p: 2 }}>
            {isLoading ? <SkeletonListAvatar rows={1} /> :
              posDialogData ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ minWidth: 40 }}>
                    <Avatar
                      src={imgLoaded ? ("https://eodhistoricaldata.com/" + logo) : getFlag(posDialogData.country)}
                      onLoad={this.handleImageLoaded}
                      onError={this.handleImageError}
                      alt="loaded"
                    />
                  </Box>
                  <Box sx={{
                    px: 2, pr: 3, flex: 1, overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                    <Typography variant='h6' className='big' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>{posDialogTitle}</Typography>
                    <Typography variant='body2' className='big' color='secondary'>{posDialogData ? posDialogData.ticker : '-'}</Typography>
                  </Box>
                  <Box sx={{ minWidth: 50 }}><Typography variant='h4' className='big'>{posDialogData ? posDialogData.perc_gain : '-'}</Typography></Box>
                </Box>) : 'No data'
            }
          </DialogTitle>
          <DialogContent>
            <Box>
              {posDialogHist && posDialogHist.length > 0 ? (
                <ReactApexChart options={options} series={options.series} type="area" height={200} />
              )
                : <SkeletonChart height={215} />
              }
            </Box>

            <Box>
              {isLoading ? <SkeletonListAvatar /> :
                posDialogData ? (
                  <Timeline sx={{ mt: 2 }} position="alternate">
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color='primary'><FlagOutlined color="white" /></TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', border: 0 }}>
                          <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle2'>{posDialogData.open_signal === 'B' ? 'Buy' : 'Sell'}</Typography>
                            <Typography variant='caption'>{posDialogData.open_date}</Typography>
                            <Typography variant='caption' color={'secondary'}>{posDialogData.open_price ? numeral(posDialogData.open_price).format('0.00') : '-'}</Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot>{max_min_items[0] ? max_min_items[0].l : '-'}</TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', border: 0 }}>
                          <Box sx={{ p: 1, display: 'inline-flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle2'>{max_min_items[0] ? max_min_items[0].desc : '-'}</Typography>
                            <Typography variant='caption'>{max_min_items[0] ? max_min_items[0].d : '-'}</Typography>
                            <Typography variant='caption' color={'secondary'}>{max_min_items[0] ? numeral(max_min_items[0].p).format('0.00') : '-'}</Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot>{max_min_items[1] ? max_min_items[1].l : '-'}</TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', border: 0 }}>
                          <Box sx={{ p: 1, flex: '70%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle2'>{max_min_items[1] ? max_min_items[1].desc : '-'}</Typography>
                            <Typography variant='caption'>{max_min_items[1] ? max_min_items[1].d : '-'}</Typography>
                            <Typography variant='caption' color={'secondary'}>{max_min_items[1] ? numeral(max_min_items[1].p).format('0.00') : '-'}</Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color={
                          posDialogData.exit_price > 0 && posDialogData.perc_gain_value > 0 ? 'buy'
                            : posDialogData.exit_price > 0 && posDialogData.perc_gain_value < 0 ? 'neg'
                              : 'info'
                        }><SportsScoreOutlined /></TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', border: 0 }}>
                          <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='subtitle2'>Exit</Typography>
                            <Typography variant='caption'>{posDialogData.exit_date}</Typography>
                            <Typography variant='caption' color={'secondary'}>{posDialogData.exit_price ? numeral(posDialogData.exit_price).format('0.00') : '-'}</Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                ) : 'No data'
              }
            </Box>

          </DialogContent>
          <DialogActions>
            <Button sx={{minWidth: 100}} disableElevation variant='outlined' onClick={props.closePos}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTheme(DialogTradeMob);