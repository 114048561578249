import { DownloadOutlined, InstallDesktopOutlined, InstallMobileOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";


const InstallPwa = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            console.log("we are being triggered :D");
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            console.log("no prompt");
            return;
        }
        console.log("prompt!");
        promptInstall.prompt();
    };
    if (!supportsPWA) {
        return null;
    }
    return (
        <Tooltip title={t("pwaInstallButton")}>
            <IconButton sx={{ mr: 1 }}
                onClick={onClick}>
                {isDesktop ? <InstallDesktopOutlined /> : <InstallMobileOutlined />}
            </IconButton>
        </Tooltip>
    );
};

export default InstallPwa;