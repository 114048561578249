import { CreditCard, CreditCardTwoTone } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Fade, Tab, Tabs, Toolbar, Tooltip } from "@mui/material";
import { Container } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import { connect, useDispatch } from "react-redux";
import { setMainTitle } from "../reduxActions/Actions";
import PaypalCheckout from "./PaypalCheckout";
import StripeCheckout from "./StripeCheckout";
import StripeEditCard from "./StripeEditCard";

const promise = loadStripe("pk_test_vXuZViKJbvXqvjwj5YC9ffF6");

function Payment() {
    const [payment, setPayment] = useState(1);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setMainTitle("Payment"))
    }, [])

    const handleChange = (event, newValue) => {
        setPayment(newValue)
    }

    return (
        <Fade in={true} timeout={450}>
            <div>
                <BrowserView>
                    <Container sx={{ p: 2 }}>
                        <Toolbar />
                        <Card>
                            <CardHeader></CardHeader>
                            <CardContent sx={{ p: 4 }}>
                                <Elements stripe={promise}>
                                    <StripeEditCard />
                                </Elements>
                            </CardContent>
                        </Card>
                    </Container>
                </BrowserView>

                <MobileOnlyView>
                    <Container sx={{ p: 2 }}>
                        <Toolbar />
                        <Box>
                            <Elements stripe={promise}>
                                <StripeEditCard />
                            </Elements>
                        </Box>
                    </Container>
                </MobileOnlyView>
            </div>
        </Fade >
    );
}

export default connect()(Payment)