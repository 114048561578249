import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Component } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { withTheme } from '@emotion/react';

class MobMzPerformancesDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      param: 'r_today',
      selected: 'r_today'
    }
  }

  handleChange(param) {
    this.setState({ param }, function () {
      this.getData()
    })
  }

  getData() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    const param = this.state.param

    axios.get(process.env.REACT_APP_API + 'v2/mz/wa/report_details/' + this.props.id, config)
      .then(response => {
        const pf = response.data[0];
        const bm = response.data[1];
        let data = [];

        var series_data = [
          {
            name: bm.name,
            data: [bm[this.state.param]]
          }, {
            name: pf.name,
            data: [pf[this.state.param]]
          }]

        var options = {
          series: series_data,
          chart: {
            type: 'bar',
            height: "100%",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            offsetX: 0
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          grid: {
            borderColor: this.props.theme.palette.grid,
            yaxis: {
              lines: {
                show: false
              }
            },
          },
          legend: {
            show: true,
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            labels: {
              colors: ["#848484"],
            },
            itemMargin: {
              vertical: 4
            },
            horizontalAlign: 'left',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              columnWidth: '80%',
              distributed: false,
              dataLabels: {
                position: 'bottom',
              },
            }
          },
          fill: {
            opacity: .7,
          },
          dataLabels: {
            enabled: true,
            offsetY: 4,
            style: {
              fontSize: '12px',
              fontFamily: 'Roboto',
              fontWeight: 600,
              colors: ["#000"]
            },
            formatter: function (val, opts) {
              return (opts.seriesIndex === 0 ? bm["real_" + param] : pf["real_" + param])
            },
          },
          stroke: {
            show: true,
            width: 4,
            colors: ['#fff']
          },
          tooltip: {
            enabled: true,
            intersect: false,
            shared: true,
            y: {
              formatter: function (val, opts) {
                return (opts.seriesIndex === 0 ? bm["real_" + param] : pf["real_" + param])
              },
            },
            x: {
              format: 'MMM YYYY',
            },
            marker: {
              show: false,
            },
          },
          annotations: {
            yaxis: [
              {
                y: 0,
                borderColor: this.props.theme.palette.grid,
                opacity: 1,
                offsetX: 0,
                offsetY: 0
              }
            ]
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            labels: {
              show: false,
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          xaxis: {
            tickAmount: 5,
            tickPlacement: 'on',
            labels: {
              show: false,
              rotate: 0,
              style: {
                colors: '#848484',
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
        };

        this.setState({ data, options });
      }).catch(error => {
        console.log(error);
      })
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { param, options } = this.state;

    return (
        <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>

        <Box sx={{height: 30}}><Typography gutterBottom variant="overline" component="h2"><b>Performances</b></Typography></Box>
        {options ? (
          <Box sx={{flex: 1}}>
            <ReactApexChart options={options} series={options.series} type='bar' height={"100%"} />
          </Box>
        ) : "-"}
        <Box sx={{ mt: 2, height: 80 ,width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid container maxWidth={300} spacing={1}>
            {[
              { 'l': 'TOTAL', 'v': 'r_today' },
              { 'l': '1M', 'v': 'r_1m' },
              { 'l': '3M', 'v': 'r_3m' },
              { 'l': '1Y', 'v': 'r_1y' },
              { 'l': '3Y', 'v': 'r_3y' },
              { 'l': 'YTD', 'v': 'r_ytd' },
            ].map((item) => {
              const checked = param === item.v;
              return (
                <Grid item xs={4}>
                  <Chip
                    sx={{ width: "100%", maxWidth: 92 }}
                    size='medium'
                    key={item.l}
                    variant={checked ? 'plain' : 'outlined'}
                    color={checked ? 'primary' : 'secondary'}
                    label={item.l}
                    onClick={() => this.handleChange(item.v)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default withTheme(MobMzPerformancesDetails);