import React, { Component } from 'react';
import { Typography, Button, ListItemSecondaryAction, ListItemText, Avatar, ListItemAvatar, Box, IconButton, ListItemButton, CircularProgress, Skeleton, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import axios from 'axios';
import DialogConfirm from '../../elements/DialogConfirm';
import DialogHelp from '../../elements/DialogHelp';
import Rating from '@mui/material/Rating';
import BrainoTooltip from '../../elements/BrainoTooltip';
import { NavLink } from 'react-router-dom';
import { CheckOutlined, DoneAll, DragIndicator, HelpOutlineOutlined, Leaderboard, MarkEmailReadOutlined, Star, Whatshot, WhatshotOutlined } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import BrainoSnackbar from '../../commons/BrainoSnackbar';
import { t } from 'i18next';
import { getFlag } from '../../commons/Utils';
import { DraggableCore } from 'react-draggable';
import { FixedSizeList } from 'react-window';
import SkeletonListAvatar from '../../elements/SkeletonListAvatar';
import DialogUpgrade from '../../elements/DialogUpgrade';
import { DragElement } from '../../commons/DragElement';
import { LoadingButton } from '@mui/lab';

const StyledRating = Rating;

class MobMyAdvices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      posConfirmOpen: false,
      upgradeOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",
      upgradeTitle: t("MyPositions_limit_reached_text"),
      upgradeText: <Trans commands={{ br: <br /> }}>MyPositions_too_many_text</Trans>,
      alarmOpen: false,
      snackOpen: false,
      snackMessage: '',
      collapsed: true,
      yTotal: window.innerHeight - 56 - 56,
      yStart: (window.innerHeight - 56 - 56) / 2, // initial position of draggable component
      yPos: (window.innerHeight - 56 - 56) / 2,
      yMax: 0,
      yDir: 0,
      listHeight: 0,
    }

    this.draggableRef = React.createRef();
    this.parentRef = React.createRef();
    this.containerRef = React.createRef();
    this.handleClose = this.handleStop.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  confirmTradeOpen(id) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.post(process.env.REACT_APP_API + 'mypos/' + localStorage.getItem('ID') + '/' + id + '/theta2', '', config)
      .then(response => {
        const data = response.data
        if (data > 0) {
          this.setState({ snackOpen: true, snackMessage: t('MyPositions_added_text') });
          this.loadData()
        } else {
          this.setState({ upgradeOpen: true })
        }
      }).catch(error => {
        console.log(error);
        // HANDLE ERROR HERE (ERROR SNACKBAR)
      })
    this.setState({ posConfirmOpen: false, posConfirmTradeId: id });
  }

  openPosConfirm() {
    this.setState({ posConfirmOpen: true });
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false, posConfirmTradeId: 0 });
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  closeUpgradeDialog() {
    this.setState({ upgradeOpen: false })
  }

  openUpgradeDialog() {
    this.setState({ upgradeOpen: true })
  }

  async componentDidMount() {
    const { current: container } = this.containerRef;
    if (container) {
      const windowHeight = window.innerHeight;
      const headerHeight = 56;
      const footerHeight = 56;
      const listHeight = windowHeight - headerHeight - footerHeight - container.offsetTop;
      this.setState({ listHeight });
    }

    this.loadData()
    this.loadEmailNotifications()
  }

  loadData = () => {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'myb/open_advice2/', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  loadEmailNotifications() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    axios.get(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        this.setState({ alarmOpen: data === "1" ? false : true })
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  enableEmailNotifications = () => {
    this.setState({ isLoading: true })
    const value = 1
    let config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } }
    axios.put(process.env.REACT_APP_API + 'social/newsletter_accept/' + localStorage.getItem('ID') + '/' + value, null, config)
      .then(response => {
        this.setState({ alarmOpen: false, snackOpen: true, snackMessage: t("settings_mail_notifications_on") })
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  closeAlarm = () => {
    this.setState({ alarmOpen: false })
  }

  handleStart = (event, ui) => {
    this.setState({
      collapsed: true,
    })
  }

  handleDrag = (event, ui) => {
    const { yStart } = this.state;
    const newY = ui.y;
    const parentTop = this.parentRef.current.getBoundingClientRect().top - 56
    if (newY <= parentTop) {
      this.setState({
        yPos: parentTop,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: false,
      });
    } else if (newY < yStart) {
      this.setState({
        yPos: newY,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    } else {
      this.setState({
        yPos: yStart,
        yDir: ui.deltaY,
        yMax: parentTop,
        collapsed: true,
      });
    }
  }

  handleStop = (event, ui) => {
    const { yDir, yStart, yMax } = this.state
    if (yDir > 5) {
      this.setState({
        yPos: yStart,
        collapsed: true,
      })
    } else if (yDir < 5) {
      this.setState({
        yPos: yMax,
      })
      setTimeout(() => {
        this.setState({
          collapsed: false,
        })
      }, 300);
    }
  }

  render() {
    const { data, isLoading, posConfirmOpen, snackOpen, snackMessage, collapsed, yPos, listHeight, yTotal, alarmOpen } = this.state;
    const bg = "white"
    const Row = ({ index, style }) => {
      const obj = data[index];
      return (
        <ListItemButton gutterBottom key={obj.id_trade} component={NavLink} to={"/detail/" + obj.id_instr} style={style} selected={obj.is_fav ? 1 : 0}>
          <ListItemAvatar>
            <Avatar alt={obj.country} src={getFlag(obj.country)} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography sx={{ whiteSpace: 'nowrap', maxWidth: '60%', overflow: "hidden", textOverflow: "ellipsis" }}><b>{obj.name}</b></Typography>}
            secondary={
              obj.advice_type === 1 ?
                <div><DoneAll color='primary' sx={{ mr: 2, fontSize: 20 }} /><StyledRating value={obj.score} max={3} readOnly size="small" /></div>
                : <div><Whatshot sx={{ mr: 2 }} /><StyledRating value={obj.score} max={3} readOnly size="small" /></div>
            } />
          <ListItemSecondaryAction>
            <BrainoTooltip title="Add to my position" arrow>
              <Button
                size='large'
                disableElevation
                color={!obj.is_traded ? (obj.open_signal === 'B' ? 'buy' : 'sell') : "secondary"}
                disabled={obj.is_traded ? 1 : 0}
                variant='contained'
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation(); // stop click event from bubbling up to ListItemButton
                  this.addPosition(obj.id_trade);
                }}
              >{obj.open_signal === 'B' ? 'Buy' : 'Sell'}</Button>
            </BrainoTooltip>
          </ListItemSecondaryAction>
        </ListItemButton>
      );
    };

    return (
      <div>
        <Dialog
          open={alarmOpen}
          fullWidth={true}
          close={this.closeAlarm}
        >
          <DialogTitle>Notifications disabled!</DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="body2"><Trans>my_mypos_alert</Trans></Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeAlarm} color='inherit' sx={{ mr: 1 }}><Trans>my_mypos_alert_btn_2</Trans></Button>
            <LoadingButton loading={isLoading} loadingPosition="start" startIcon={<MarkEmailReadOutlined />} onClick={this.enableEmailNotifications} variant='contained' disableElevation><Trans>my_mypos_alert_btn_1</Trans></LoadingButton>
          </DialogActions>
        </Dialog>

        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={t("MyAdvicesTitle")}
          helpDialogContent={
            <Trans
              components={{
                done_all: <DoneAll color='primary' />,
                whatshot: <WhatshotOutlined color='secondary' />,
                star: <Star color='warning' />,
                small: <small />,
                em: <em />,
                mailTo_professionals: <a target="_blank" rel="noopener noreferrer" href='mailto:professionals@wavenure.com?subject=Information request'> </a>,
              }}
            >
              {"MyAdvicesHelp"}
            </Trans>
          }
          okButton="ok" />

        <DialogConfirm
          id={this.state.posConfirmTradeId}
          closeLoginDialog={() => this.closePosConfirm()}
          isLoginDialogOpen={posConfirmOpen}
          confirmTradeOpen={() => this.confirmTradeOpen(this.state.posConfirmTradeId)}
          posDialogTitle="Aggiungi Posizione"
          posDialogContent="Vuoi aggiungere la posizione alla lista? Per ricevere notifiche sulle posizioni in chiusura è necessario abilitare l'opzione inviami notifiche, newsletter di informativa finanziaria e marketing nelle impostazioni."
          cancelButton="cancel"
          okButton="ok" />

        <BrainoSnackbar
          open={snackOpen}
          onClose={() => { this.setState({ snackOpen: false }) }}
          message={snackMessage}
        />

        <DialogUpgrade
          closeUpgradeDialog={() => this.closeUpgradeDialog()}
          isUpgradeDialogOpen={this.state.upgradeOpen}
          upgradeDialogTitle={this.state.upgradeTitle}
          upgradeDialogContent={this.state.upgradeText}
        />

        <Box ref={this.parentRef} sx={{ display: 'flex', flexDirection: 'column', height: yTotal, overflow: 'hidden', position: 'relative' }}>
          <Box sx={{ p: 2, display: 'flex', flex: '50%', position: 'relative', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center' }}><Typography variant="h4" className='big'>Hello</Typography></Box>
              <Box sx={{ flex: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <IconButton component={NavLink} to={'/mystats'} color='primary'><Leaderboard /></IconButton>
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ maxHeight: '240px' }} src="img/MobMyAdvices.svg" />
              </Box>
              <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='caption' color="textSecondary">You have</Typography>
                <Typography variant='h2' className='big' data-testid="advices">{isLoading ? <Skeleton width={60} /> : data.length}</Typography>
                <Typography variant='caption' color="textSecondary">new ideas</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flex: '50%', position: 'relative' }}>
          </Box>

          <DraggableCore
            axis="y" handle=".handle" onDrag={this.handleDrag} onStop={this.handleStop} onStart={this.handleStart}
          >
            <Box ref={this.draggableRef} style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              cursor: 'move',
              border: '1px solid #e6e6e6',
              borderBottom: 0,
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8, background: bg, maxHeight: '100%', top: yPos, transition: "all 300ms cubic-bezier(.22,.68,0,1.4)"
            }}>
              <Box className='handle' sx={{
                borderTopRightRadius: 8,
                borderTopLeftRadius: 8,
                background: bg,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 2,
              }}>
                <DragElement />
              </Box>
              <Box ref={this.containerRef} sx={{ background: bg, maxHeight: "100%", overflow: collapsed ? "hidden" : "hidden", }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: '50%' }}>
                    <Typography sx={{ px: 2, }} gutterBottom variant="overline">{t("MyAdvicesTitle")}</Typography>
                  </Box>
                  <Box sx={{ flex: '50%', display: "flex", justifyContent: "end", mr: 1 }}>
                    <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                      <HelpOutlineOutlined />
                    </IconButton>
                  </Box>
                </Box>
                {isLoading ? <Box sx={{ px: 2 }}><SkeletonListAvatar rows={5} /></Box> :
                  data.length > 0 ?
                    <FixedSizeList height={listHeight + 40}
                      itemCount={data.length}
                      itemSize={80}
                      width={'100%'}>
                      {Row}
                    </FixedSizeList>
                    : <Empty />
                }
              </Box>
            </Box>
          </DraggableCore>
        </Box >
      </div >
    );
  }
}

export default React.memo(MobMyAdvices);

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', m: 1, py: 2, bgcolor: "#f0f0f0", borderRadius: 4, alignItems: "center" }}>
          <Box sx={{ p: 0, flex: '40%' }}><img src={'/img/empty/MyAdvicesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 1, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography variant='subtitle2'><Trans><b>{t('MyAdvices_emptyTitle')}</b></Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('MyAdvices_empty')}</Trans></Typography></Box>
          </Box>
        </Box>
      </>
    )
  }
}