import { Box, Button, Typography } from '@mui/material';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class EmptyContent extends Component {
    render() {
        return (
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ p: 2, flex: '40%'}}><img src={'/img/empty/' + this.props.img + 'Empty.svg'} style={{maxWidth: 150}} /></Box>
                    <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Box><Typography variant='subtitle2'>{this.props.title}</Typography></Box>
                        <Box><Typography variant='body2'> <Trans>{this.props.comment}</Trans></Typography></Box>
                        {this.props.cta ? <Box sx={{mt: 2}}><Button variant='outlined'>CTA HERE</Button></Box> : ''}
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default EmptyContent;