import { BadgeOutlined, ChevronLeftOutlined, DonutLargeOutlined, ExitToAppOutlined, FeedbackOutlined, HomeOutlined, LeaderboardOutlined, Menu, OndemandVideoOutlined, PersonOutline, RefreshOutlined, Settings, ShoppingCartOutlined, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { AppBar, Box, Container, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider, Toolbar, Tooltip, Typography, createTheme } from '@mui/material';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import packageJson from '../package.json';
import './App.css';
import darktheme from './commons/DarkTheme';
import { GlobalContext } from './commons/GlobalContext';
import lighttheme from './commons/Theme';
import eventBus from './commons/eventBus';
import EditCard from './ecommerce/EditCard';
import Payment from './ecommerce/Payment';
import Paypal from './ecommerce/Paypal';
import Login from './elements/Login';
import Checkout from './pages/Checkout';
import Identify from './pages/Identify';
import Instrument from './pages/Instrument';
import Invoice from './pages/Invoice';
import MainOverview from "./pages/MainOverview";
import MyAccount from './pages/MyAccount';
import MyBraino from "./pages/MyBraino";
import MyInvoices from './pages/MyInvoices';
import MyPortfolios from './pages/MyPortfolios';
import MyProfile from "./pages/MyProfile";
import MySettings from "./pages/MySettings";
import MyStats from './pages/MyStats';
import PasswordCreate from './pages/PasswordCreate';
import PasswordReset from './pages/PasswordReset';
import PaymentCanceled from './pages/PaymentCanceled';
import PaymentCompleted from './pages/PaymentCompleted';
import PaymentFailed from './pages/PaymentFailed';
import Plans from './pages/Plans';
import Portfolios from "./pages/Portfolios";
import PortfoliosOverview from "./pages/PortfoliosOverview";
import Profiling from './pages/Profiling';
import Signup from "./pages/Signup";
import SignupResult from './pages/SignupResult';
import TestShop from './pages/TestShop';
import InstallPwa from './pwa/InstallPwa';
import Index from './test/Index';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? lighttheme.palette : darktheme.palette),
  },
});
let theme = createTheme(getDesignTokens('light'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'light',
      drawerOpen: false,
      logged: localStorage.getItem('TOKEN'),
      toolbarTitle: 'brAIno',
      newVersion: false,
    }
  }

  changeTheme() {
    let prevMode = this.state.mode
    console.log("isMobile", isMobile)
    if (prevMode === 'light') {
      this.setState({ mode: 'dark' });
      theme = createTheme(getDesignTokens('dark'))
      theme.components = darktheme.components
    } else {
      this.setState({ mode: 'light' });
      theme = createTheme(getDesignTokens('light'))
      theme.components = lighttheme.components
    }
  }

  manageProfilingRedirection(){
    if (localStorage.getItem("QST") > 0 && window.location.hash.indexOf("/profiling") == -1) {
      if (window.location.hash.indexOf("/payment") == -1) {
        console.log(window.location.hash)
        this.props.navigate("/profiling", { replace: true });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(localStorage.getItem("QST"), localStorage.getItem("VF"))

    this.manageProfilingRedirection()

    if (prevProps.loginStatusUpdate !== this.props.loginStatusUpdate) {
      console.log("Update - loginStatusUpdate", this.props.loginStatusUpdate)
      if (!localStorage.getItem('TOKEN') || localStorage.getItem('TOKEN') == null) {
        this.logout()
      }
    }
  }

  componentWillUnmount() {
    eventBus.remove("NEWVERSION")
  }

  componentDidMount() {
    if (!localStorage.getItem('TOKEN') || localStorage.getItem('TOKEN') == null || !this.state.logged) {
      this.logout()
    }
    eventBus.on("NEWVERSION", () => {
      console.log("<<< NEWVERSION HAS BEEN DETECTED")
      this.setState({ newVersion: true })
    })
    console.log("Initializing Braino...")

    if (isMobile) this.setState({ toolbarTitle: localStorage.getItem("TITLE") })

    let lang = localStorage.getItem('LANG') || navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2);
    localStorage.setItem("LANG", lang);

    this.setState({ mode: 'light' })
    theme = createTheme(getDesignTokens('light'));
    theme.components = lighttheme.components
    if (this.state.logged) this.props.navigate('/home')
  }

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  closeDrawer() {
    this.setState({ drawerOpen: 0 })
  }

  login() {
    this.setState({ logged: true })
    this.props.navigate('/home')
  }

  logout() {
    this.closeDrawer()
    for (var obj in localStorage) {
      if (localStorage.hasOwnProperty(obj) && obj !== "LANG" && !obj.startsWith("ONB_")) {
        localStorage.removeItem(obj);
      }
    }
    for (var obj in sessionStorage) {
      localStorage.removeItem(obj);
    }
    this.setState({ logged: false })
    this.props.navigate('/', { replace: true })
    console.log("logging out: navigate login");
    this.props.navigate('/', { replace: true })
  }

  handleRefreshClick = () => {
    window.location.reload();
  };

  globalCheck = () => {
    console.log("global checking...")
  }

  render() {
    const { drawerOpen, logged, toolbarTitle, newVersion } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl" sx={{ p: 0 }}>
          <>
            {localStorage.getItem("TOKEN") ?
              <>
                <AppBar position="fixed">
                  <Toolbar variant='regular'>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => this.toggleDrawer()}
                      edge="start"
                    >
                      <Menu />
                    </IconButton>
                    <Typography variant="body1" noWrap sx={{ flexGrow: 1, fontWeight: 300, ml: 1 }}>
                      {isMobile ? this.props.title : ''}
                    </Typography>
                    {newVersion && (
                      <Tooltip title="Update version">
                        <IconButton onClick={this.handleRefreshClick} sx={{ mr: 1 }}>
                          <RefreshOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    <InstallPwa />
                    <IconButton sx={{ mr: 1 }} color="inherit" component={NavLink} to='/home'>
                      <HomeOutlined />
                    </IconButton>
                    <IconButton color="inherit" component={NavLink} to='/plans'>
                      <ShoppingCartOutlined color='primary' />
                    </IconButton>
                    {/* <IconButton color="inherit" onClick={() => this.changeTheme()}>
                      {mode == 'light' ? <DarkModeOutlined color="inherit" /> : <LightModeOutlined color="inherit" />}
                    </IconButton> */}
                  </Toolbar>
                </AppBar>
                <Drawer
                  sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
                  variant="temporary"
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => this.closeDrawer()}
                  onBackdropClick={() => this.closeDrawer()}
                >
                  <div>
                    <IconButton onClick={() => this.closeDrawer()}>
                      {<ChevronLeftOutlined />}
                    </IconButton>
                  </div>
                  <ListItem>
                    <ListItemText primary={<Typography variant='caption' className='slim'>{localStorage.getItem("EMAIL")}</Typography>} />
                  </ListItem>
                  <Divider />
                  <List sx={{}}>
                    {[
                      { 'l': '/home', 'n': 'Home', 'i': <HomeOutlined /> },
                      { 'l': '/mybraino', 'n': 'My Braino', 'i': <TipsAndUpdatesOutlined /> },
                      { 'l': '/portfolios', 'n': 'My Portfolios', 'i': <DonutLargeOutlined /> },
                      { 'l': '/mystats', 'n': 'My Stats', 'i': <LeaderboardOutlined /> },
                    ].map((obj) => (
                      <ListItem key={obj.n} disablePadding>
                        <ListItemButton onClick={() => this.closeDrawer()} component={NavLink} to={obj.l}>
                          <ListItemIcon>
                            {obj.i}
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant='subtitle2'>{obj.n}</Typography>} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <List>
                    {[
                      { 'l': '/profile', 'n': 'Profile', 'i': <BadgeOutlined /> },
                      { 'l': '/account', 'n': 'Account', 'i': <PersonOutline /> },
                      { 'l': '/settings', 'n': 'Settings', 'i': <Settings /> },
                      { 'l': '/videos', 'n': 'Videos', 'i': <OndemandVideoOutlined /> },
                      { 'l': '/feedback', 'n': 'Feedback', 'i': <FeedbackOutlined /> },
                      //{ 'l': '/mybraino', 'n': 'Logout', 'i': <ExitToAppOutlinedIcon /> },
                    ].map((obj) => (
                      <ListItemButton onClick={() => this.closeDrawer()} button key={obj.n} component={NavLink} to={obj.l}>
                        <ListItemIcon>{obj.i}</ListItemIcon>
                        <ListItemText primary={<Typography variant='subtitle2'>{obj.n}</Typography>} />
                      </ListItemButton>
                    ))}
                    <Divider />
                    <ListItem onClick={() => this.logout()} component={NavLink} to={'/'} button>
                      <ListItemIcon><ExitToAppOutlined /></ListItemIcon>
                      <ListItemText primary={<Typography variant='subtitle2'>Logout</Typography>} />
                    </ListItem>
                  </List>

                  <Box sx={{ position: 'fixed', bottom: 8, left: 8 }}>
                    <Typography variant='caption'>{packageJson.version}</Typography>
                  </Box>
                </Drawer>
              </>
              : ''}
            <Box sx={{ flexGrow: 1, p: 0 }}>
              <GlobalContext.Provider value={toolbarTitle}>
                <Routes>
                  <Route exact path="/" element={<Login onmo login={() => this.login()} />} />
                  <Route exact path="/home" element={<MainOverview />} />
                  <Route exact path="/portfolios" element={<PortfoliosOverview />} />
                  <Route path="/portfolio/:id" element={<Portfolios />} />
                  <Route path="/detail/:id" element={<Instrument />} />
                  <Route exact path="/mybraino" element={<MyBraino />} />
                  <Route exact path="/mystats" element={<MyStats />} />
                  <Route exact path="/profile" element={<MyProfile />} />
                  <Route exact path="/settings" element={<MySettings />} />
                  <Route exact path="/account" element={<MyAccount />} />
                  <Route exact path="/invoices" element={<MyInvoices />} />
                  <Route path="/invoice/:id" element={<Invoice />} />
                  <Route exact path="/profiling" element={<Profiling />} />
                  <Route exact path="/plans" element={<Plans />} />
                  <Route exact path="/editCard" element={<EditCard />} />
                  <Route exact path="/checkout" element={<Checkout />} />
                  <Route exact path="/signup" element={<Signup />} />
                  <Route exact path="/reset" element={<PasswordReset />} />
                  <Route exact path="/mail_validation_success.html" element={<SignupResult />} />
                  <Route exact path="/mail_validation_failed.html" element={<SignupResult />} />
                  <Route path="/newPassword/:token" element={<PasswordCreate />} />
                  <Route exact path="/testShop" element={<TestShop />} />
                  <Route exact path="/myportfolios" element={<MyPortfolios />} />
                  <Route exact path="/payment" element={<Payment />} />
                  <Route exact path="/paypal" element={<Paypal />} />
                  <Route path="/paymentCompleted/:paymentType" element={<PaymentCompleted />} />
                  <Route path="/paymentFailed/:paymentType" element={<PaymentFailed />} />
                  <Route path="/paymentCanceled/:paymentType" element={<PaymentCanceled />} />
                  <Route path="/identify" element={<Identify />} />
                  <Route path="/test" element={<Index />} />
                </Routes>
              </GlobalContext.Provider>
            </Box>
          </>
        </Container>
      </ThemeProvider>
    );
  }
}

export function AppWithRouter(props) {
  const navigate = useNavigate()

  const title = useSelector(state => state.title)
  const loginStatusUpdate = useSelector(state => state.loginStatusUpdate)
  const dispatch = useDispatch()
  return <App navigate={navigate} title={title} loginStatusUpdate={loginStatusUpdate} dispatch={dispatch} {...props} />
}

export default connect()(App);
