import React, { Component } from 'react';
import { Container, Link } from '@mui/material';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

class TestShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: localStorage.getItem('LANG')
    }
  }

  showCatalog = () => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    axios.get('http://192.168.1.111:3001/v1/view/products', config).then(response => {
      window.location.href = response.data.redirect;
    })
  }

  showOrders = () => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    axios.get('http://192.168.1.111:3001/v1/view/orders', config).then(response => {
      window.location.href = response.data.redirect;
    })
  }

  getOrders = () => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    axios.get('http://192.168.1.111:3001/v1/orders', config).then(response => {
      console.log(response.data)
    })
  }

  getProducts = () => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    axios.get('http://192.168.1.111:3001/v1/products', config).then(response => {
      console.log(response.data)
    })
  }

  getSubs = () => {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }

    axios.get('http://192.168.1.111:3001/v1/products', config).then(response => {
      console.log(response.data)
    })
  }

  componentDidMount() {
  }

  render() {
    return (
      <Container sx={{ p: 2, mt:14 }}>
        <Link sx={{mt:14}} onClick={this.showCatalog}>go to catalog</Link>
        <br/>
        <Link sx={{mt:14}} onClick={this.showOrders}>go to orders</Link>
        <br/>
        <Link sx={{mt:14}} onClick={this.getOrders}>get orders</Link>
        <br/>
        <Link sx={{mt:14}} onClick={this.getProducts}>get products</Link>
        <br/>
        <Link sx={{mt:14}} onClick={this.getSubs}>get subs</Link>
      </Container>
    );
  }
}

export default withTranslation()(TestShop);