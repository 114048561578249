import { EmojiEventsOutlined, HelpOutline as HelpOutlineOutlinedIcon, MilitaryTechOutlined, WorkspacePremiumOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import axios from 'axios';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import DialogHelp from '../elements/DialogHelp';
import SkeletonList from '../elements/SkeletonListAvatar';

class MyStatsRanking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      rank: [],
      tip: [],
      helpOpen: false,
      helpText: ""
    }
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/myst/wa/rank/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        this.setState({ isLoading: false, rank: data.ranking, tip: data.tip });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  render() {
    const { rank, tip, isLoading } = this.state;
    const { classes } = this.props;

    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsRankingTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsRankingHelp
            </Trans>
          }
          okButton="ok" />

        <Card variant="outlined" className='fullHeightCard'>
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2">{<Trans>MyStatsRankingTitle</Trans>}</Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent sx={{minHeight: 563}}>
            <List>
              {rank && rank.length > 0 ? rank.map((obj, index) => (
                <div>
                  {
                    index === 3 ?
                      <ListItem>
                        <ListItemAvatar></ListItemAvatar>
                        <ListItemAvatar>...</ListItemAvatar>
                      </ListItem>
                      : ''
                  }

                  <ListItem selected={obj.you !== "0" ? 1 : 0}>
                    <ListItemAvatar>
                      {
                        obj.posn === "1" ? <EmojiEventsOutlined color='golden' /> :
                          obj.posn === "2" ? <MilitaryTechOutlined color='silver' /> :
                            obj.posn === "3" ? <WorkspacePremiumOutlined color='bronze' /> : ''
                      }
                    </ListItemAvatar>
                    <ListItemAvatar>{obj.posn}</ListItemAvatar>
                    <ListItemText>{obj.you !== "0" ? 'You' : 'user_' + obj.id_user}</ListItemText>
                    <ListItemSecondaryAction>{numeral(obj.avg_profit).format('+0.0') + '%'}</ListItemSecondaryAction>
                  </ListItem>

                  {
                    index === (rank.length - 1) ?
                      <ListItem>
                        <ListItemAvatar></ListItemAvatar>
                        <ListItemAvatar>...</ListItemAvatar>
                      </ListItem>
                      : ''
                  }
                </div>
              )) : <SkeletonList></SkeletonList>
              }
            </List>
            <Divider />
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: '50%', textAlign: 'center', p: 3 }}>
                <Trans components={{
                  headline6: <Typography variant='h6'></Typography>
                }}>{'myst_tip_' + tip.key}</Trans>
              </Box>
              <Box sx={{ flex: '50%', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', p: 3 }}>
                {
                  Array.isArray(tip.tip) ? (
                    tip.tip.map((obj, index) => (
                      <Typography className='big' variant='h5'>{obj}</Typography>
                    ))
                  ) :
                  <Typography className='big' variant='h3'>{tip.tip}</Typography>
                }
            </Box>
          </Box>
        </CardContent>
      </Card>
      </>
    );
  }
}

export default MyStatsRanking;
