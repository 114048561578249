import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Collapse, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { t } from 'i18next';
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import BrainoSnackError from "../commons/BrainoSnackError";
import { NavLink } from "react-router-dom";

const CardInputWrapper = styled.div`
    border: 1px solid #b9b9bf;
  border-radius: 4px;
  padding: 16px;
`;

const initialFormData = Object.freeze({
    payment_type: 2,
    id_product: 1,
    name: "",
    last_name: "",
    country: "",
    billing: "1",
});

const errors = Object.freeze({
    generic_decline: t("ecommerce_generic_decline"),
    insufficient_funds: t("ecommerce_insufficient_funds"),
    lost_card: t("ecommerce_lost_card"),
    stolen_card: t("ecommerce_stolen_card"),
    expired_card: t("ecommerce_expired_card"),
    incorrect_cvc: t("ecommerce_incorrect_cvc"),
    processing_error: t("ecommerce_processing_error"),
    incorrect_number: t("ecommerce_incorrect_number"),
})

const initialCardData = Object.freeze({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
});

export default function StripeCheckout() {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [currency, setCurrency] = useState('');
    const [recurring, setRecurring] = useState('');
    const [countries, setCountries] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountDisplay, setAmountDisplay] = useState(0);
    const stripe = useStripe();
    const elements = useElements();
    const [formData, updateFormData] = React.useState(initialFormData);
    const [cardData, updateCardData] = React.useState(initialCardData);
    const [cardErrors, setCardErrors] = React.useState(errors);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");


    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }

        axios.get(process.env.REACT_APP_API + 'shop/countries', config)
            .then(response => {
                const data = response.data
                setCountries(data)
            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })

    }, []);

    React.useEffect((event) => {
        chechFormData()
        if (formData.country && formData.billing) fetchPrice()
    }, [formData.country]);

    React.useEffect((event) => {
        chechFormData()
        if (formData.country && formData.billing) fetchPrice()
    }, [formData.billing]);

    React.useEffect((event) => {
        chechFormData()
    }, [formData.name]);

    React.useEffect((event) => {
        chechFormData()
    }, [formData.last_name]);

    const css = {
        base: {
            border: '1px solid #000!important',
            color: '#32325D',
            fontWeight: 400,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '14px',
            padding: "8px",
            ':-webkit-autofill': {
                color: '#848484',
            },
            '::placeholder': {
                color: '#848484',
            },
        },
        invalid: {
            color: '#E57373',
            '::placeholder': {
                color: '#E57373',
            },
        }
    }

    const cardStyle = {
        showIcon: true,
        iconStyle: "solid",
        style: css
    };

    const expiryStyle = {
        showIcon: true,
        iconStyle: "solid",
        placeholder: t("stripe_change_card_expiry_placeholder"),
        style: css
    };

    const cvcStyle = {
        showIcon: true,
        iconStyle: "solid",
        placeholder: t("stripe_change_card_cvc_placeholder"),
        style: css
    };

    const handleChange = (event) => {
        if (event.target)
            updateFormData({
                ...formData,
                // Trimming any whitespace
                [event.target.name]: event.target.value.trim()
            });

        chechFormData()
    };

    const handleCardChange = (event) => {
        if (event.error) {
            console.log('Card number error:', event.error.message);
        } else {
            console.log('Card number is valid');
        }
        updateCardData({
            ...cardData,
            // Trimming any whitespace
            [event.elementType]: event.complete
        });
    }

    React.useEffect((event) => {
        chechFormData()
    }, [cardData]);

    const chechFormData = () => {
        const allValuesAreTruthy = Object.values(formData).every((value) => Boolean(value));
        const allCardValuesAreTruthy = Object.values(cardData).every((value) => Boolean(value));
        if (allValuesAreTruthy && allCardValuesAreTruthy) setDisabled(false);
        else setDisabled(true)
    }

    const fetchPrice = async () => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
        axios.get(process.env.REACT_APP_API + 'shop/price_info/' + formData.id_product + '/' + formData.country + '/' + formData.billing, config)
            .then(response => {
                const data = response.data
                setAmount(data.amount * 100)
                setCurrency(data.currency)
                setAmountDisplay(data.amount)
                setRecurring(data.freq)
            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        // Create a source using the card element
        const { source, error } = await stripe.createSource(elements.getElement(CardNumberElement));

        if (error) {
            console.error(error);
            // HANDLE ERROR HERE (ERROR SNACKBAR)
        } else {
            var secure3dNeeded = source.card.three_d_secure;

            if (secure3dNeeded === "required") securePayment(source.id)
            else directPayment()
        }
    };

    const directPayment = async (stripeToken) => {
        console.log("Direct payment running")

        const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement));
        if (token) {
            const formDataObject = new FormData();
            for (const property in formData) {
                formDataObject.append(property, formData[property]);
            }
            formDataObject.append("token", token.id);

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
                }
            }
            axios.post(process.env.REACT_APP_API + 'shop/confirm_order/' + localStorage.getItem('ID'), formDataObject, config)
                .then(response => {
                    const data = response.data
                    if (data.error) {
                        const code = data.error.decline_code
                        console.log(code, t(`ecommerce_${code}`))
                        setSnackMessage(t(`ecommerce_${code}`))
                        setSnackOpen(true)
                    } else {
                        setSucceeded(true)
                    }
                    // HANDLE SUCCESS
                }).catch(error => {
                    console.log(error);
                    setSnackMessage(error)
                    setSnackOpen(true)
                }).finally(() => {
                    setProcessing(false)
                })
        } else console.log(error)
    }

    const securePayment = async (sourceId) => {
        console.log("Secure payment running")
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
            }
        }
        axios.post(process.env.REACT_APP_API + 'shop/three_d_secure_order_info/' + localStorage.getItem('ID') + '/' + sourceId, '', config)
            .then(response => {
                const data = response.data
                secureRedirect(data, sourceId)

            }).catch(error => {
                console.log(error);
                // HANDLE ERROR HERE (ERROR SNACKBAR)
            })
    }

    const secureRedirect = async (data, sourceId) => {
        const refund = data.refund;
        const customer = data.customer;
        const card = data.source;
        var http_params = new URLSearchParams(formData)

        const { source, error } = await stripe.createSource({
            type: 'three_d_secure',
            amount: (amount - (refund * 100)),
            currency: "eur",
            three_d_secure: {
                customer: customer,
                card: card
            },
            redirect: {
                return_url: process.env.REACT_APP_API + "shop/stripe_3ds_callback?" + http_params + '&card_source=' + sourceId + '&email=' + encodeURIComponent(localStorage.getItem('EMAIL')) + '&customer=' + customer
            }
        })
        if (error) {
            console.log(error);
        } else {
            window.location = source.redirect.url
        }
    }

    return (
        <>
            <Dialog
                open={snackOpen}
                onClose={() => { setSnackOpen(false) }}
            >
                <Alert severity="error"><Typography variant="subtitle2">{snackMessage}</Typography></Alert>
            </Dialog>
            <Dialog
                open={succeeded}
                onClose={() => { setSnackOpen(false) }}
            >
                <Alert severity="success"
                    action={
                        <Button size="medium" component={NavLink} to={"/home"}>
                            Home
                        </Button>
                    }>
                    <Typography variant="subtitle2">Payment succeeded. Your profile will be updated within few minutes.</Typography>
                </Alert>
            </Dialog>

            <form id="payment-form" onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                    <Typography variant="overline" gutterBottom><Trans>ecommerce_invoicing_data</Trans></Typography>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                        Email: {localStorage.getItem("EMAIL")}
                    </FormControl>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                        <TextField sx={{ width: '100%' }}
                            required
                            name="name"
                            onChange={handleChange}
                            label={<Trans>ecommerce_firstname_label</Trans>}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                        <TextField sx={{ width: '100%' }}
                            required
                            name="last_name"
                            onChange={handleChange}
                            label={<Trans>ecommerce_lastname_label</Trans>}
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl sx={{ pb: 2 }}>
                        <InputLabel id="country-select"><Trans>ecommerce_country_label</Trans> *</InputLabel>
                        <Select
                            required
                            name="country"
                            onChange={handleChange}
                            labelId="country-select"
                            id="country-select"
                            value={formData.country ? formData.country : ""}
                            label="Country *">
                            {countries && countries.map(item =>
                            (<MenuItem value={item["2_code"]}>
                                {item.name}
                            </MenuItem>)
                            )}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                        <FormLabel id="billing-select"><Typography variant="body2"><Trans>ecommerce_billing_label</Trans> *</Typography></FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            id="billing-select"
                            name="billing"
                            value={formData.billing}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="1" control={<Radio />} label={t("ecommerce_billing_month_label")} />
                            <FormControlLabel value="2" control={<Radio />} label={t("ecommerce_billing_year_label")} />
                        </RadioGroup>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                    <Collapse in={currency}>
                        <Typography variant="subtitle1">
                            <b>
                                Price: {currency ? (new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency }).format(amountDisplay)) + "/" + recurring : "Select your Coutry"}
                            </b>
                        </Typography>
                    </Collapse>
                    </FormControl>
                    <Typography variant="overline" gutterBottom><Trans>ecommerce_payment_data</Trans></Typography>
                    <FormControl variant="outlined" sx={{ pb: 2 }}>
                        <CardInputWrapper>
                            <CardNumberElement onChange={handleCardChange} options={cardStyle} />
                        </CardInputWrapper>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ pb: 2, display: "flex", flexDirection: "row" }}>
                        <Box sx={{ flex: "50%", mr: 1, border: 1, borderColor: "#b9b9bf", p: 2, borderRadius: 1 }}><CardExpiryElement onChange={handleCardChange} options={expiryStyle} /></Box>
                        <Box sx={{ flex: "50%", ml: 1, border: 1, borderColor: "#b9b9bf", p: 2, borderRadius: 1 }}><CardCvcElement onChange={handleCardChange} options={cvcStyle} /></Box>
                    </FormControl>
                    <Box sx={{ pb: 2, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "start", md: "center" } }}>
                        <Typography variant="caption" sx={{ mr: 2 }}><Trans>ecommerce_accepted_cards_text</Trans></Typography>
                        <Box sx={{ display: "flex" }}>
                            {[1, 2, 10, 16, 22].map(item =>
                                <Box sx={{ mr: 1 }}><img src={"img/cards/" + item + ".svg"} height={25} style={{ borderRadius: "4px", border: "1px solid #e6e6e6" }} /></Box>
                            )}
                        </Box>
                    </Box>
                    <FormControl variant="outlined" sx={{ py: 2 }}>
                        <LoadingButton loading={processing} type="submit" disableElevation disabled={processing || disabled || succeeded} variant="contained" size="large" loadingPosition="start"><Trans>ecommerce_subscribe</Trans></LoadingButton>
                    </FormControl>
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    )}
                </Box>
            </form >
        </>
    );
}