import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import axios from 'axios';
import { CardMedia, Fade } from '@mui/material';
import { withTheme } from '@emotion/react';

class SignupResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            newsletter1: true,
            newsletter2: false,
            open: false,
            username: null,
            password: null,
            errorOpen: false,
            errorMsg: '',
            done: true,
        }
    }

    signup() {
        this.setState({ isLoading: true });
        let config = { email: this.state.username, password: this.state.password, newsletter: this.state.newsletter1, newsletter2: this.state.newsletter2 }
        axios.post(process.env.REACT_APP_API + 'register', config)
            .then(response => {
                const data = response.data
                this.setState({ data, isLoading: false });

                if (data.result === 'OK') {
                    this.setState({done: true})
                } else {
                    this.setState({ errorOpen: true, errorMsg: data.msg })
                }
            }).catch(error => {
                console.log(error);
                this.setState({ isLoading: false });
            })
    }

    handleChange(type, value) {
        this.setState({ [type]: value })
        if (type === 'newsletter1')
            this.setState({ open: !value })
        console.log(this.state)
    }


    render() {
        return (
            <Fade in="true" timeout={450}>
                <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                    <Card variant="outlined" sx={{ width: '100%' }}>
                        <CardMedia component="img"
                    height="auto" image='/img/Plans.svg'></CardMedia>
                        <CardContent sx={{ padding: '32px'}}>
                            <p>Esito validazione</p>
                            Successo/Fallimento
                        </CardContent>
                    </Card>
                </Container>
            </Fade>
        )
    }
}
export default withTheme(SignupResult);