import { withTheme } from '@emotion/react';
import Rating from '@mui/material/Rating';
import { Text, View } from '@react-pdf/renderer';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';

const StyledRating = Rating;

class MyAdvices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      helpOpen: false,
      helpText: "",

      instrument: 0,
      schedeOpen: false,

      snackOpen: false,
      snackMessage: '',
    }
  }

  confirmTradeOpen(id) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.post(process.env.REACT_APP_API + 'mypos/' + localStorage.getItem('ID') + '/' + id + '/theta2', '', config)
      .then(response => {
        const data = response.data
        if (data > 0) {
          this.props.notifyNewPosition(id);
          this.setState({ snackOpen: true, snackMessage: t('MyPositions_added_text') });
        } else {
          this.setState({ helpTitle: 'Warning', helpText: 'Non puoi aggiungere altre posizioni', helpOpen: true })
        }
      }).catch(error => {
        console.log(error);
        // HANDLE ERROR HERE (ERROR SNACKBAR)
      })
    this.setState({ posConfirmOpen: false, posConfirmTradeId: id });
  }

  openPosConfirm() {
    this.setState({ posConfirmOpen: true });
  }

  closePosConfirm() {
    this.setState({ posConfirmOpen: false, posConfirmTradeId: 0 });
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  openSchede(id) {
    this.setState({ schedeOpen: true, instrument: id })
  }
  closeSchede() {
    this.setState({ schedeOpen: false })
  }

  loadAdvices() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'myb/open_advice2/', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }
  async componentDidMount() {
    this.loadAdvices()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.neededReload && this.props.neededReload) {
      console.log(prevProps.neededReload, this.props.neededReload, 'reload Advices')
      this.loadAdvices()
    }
  }

  render() {
    const { data } = this.state;

    return (
      <View>
        {
          data.length > 0 && data.map((obj) => (
            <Text>{obj.name}</Text>
          ))
        }
      </View>
    );
  }
}

export default withTheme(MyAdvices);
