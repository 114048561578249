import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Close, EmailOutlined } from '@mui/icons-material';
import { IconButton, Alert, Box, Collapse, Fade, Typography, InputAdornment } from '@mui/material';
import { withTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { LoadingButton } from '@mui/lab';

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoading: false,
            open: false,
            username: null,
            errorOpen: false,
            errorMsg: '',
            done: false,
        }
    }

    handleSubmit() {
        this.setState({ isLoading: true, errorOpen: false });
        let config = { email: this.state.username }
        axios.post(process.env.REACT_APP_API + 'resetpwd1', config)
            .then(response => {
                const data = response.data
                this.setState({ data, isLoading: false });

                if (data.result === 'OK') {
                    this.setState({ done: true })
                } else {
                    this.setState({ errorOpen: true, errorMsg: data.msg })
                }
            }).catch(error => {
                console.log(error);
                this.setState({ isLoading: false });
            })
    }

    handleChange(type, value) {
        this.setState({ [type]: value })
    }

    render() {
        const { isLoading, errorOpen, errorMsg, done } = this.state;
        return (
            <Fade in="true" timeout={450}>
                <div>
                    <BrowserView>
                        <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            <Card variant="outlined" sx={{ width: '100%' }}>
                                <CardContent sx={{ padding: '32px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                                            <img style={{ margin: '16px' }} height={80} alt="Logo" src="/LogoColVert.svg" />
                                        </Box>
                                        <Box sx={{ pb: 4 }}>
                                            <Box sx={{ flex: '100%' }}>
                                                <Typography variant='h4' className='big'><Trans>forgot_title</Trans></Typography>
                                                <Typography variant='caption' color={'secondary'}><Trans>forgot_text</Trans></Typography>
                                            </Box>
                                        </Box>
                                        {!done ?
                                            <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, width: '100%' }}>
                                                <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                    <TextField
                                                        label="Email"
                                                        variant="outlined"
                                                        onChange={e => this.handleChange('username', e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <EmailOutlined color='inherit' />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormControl>
                                                <Collapse timeout={150} in={errorOpen}>
                                                    <Alert
                                                        severity="warning"
                                                        color='error'
                                                        action={
                                                            <IconButton
                                                                aria-label="close"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() => {
                                                                    this.setState({ errorOpen: false })
                                                                }}><Close fontSize="inherit" /></IconButton>
                                                        }
                                                        sx={{ mb: 2 }}>

                                                        <Trans>{errorMsg}</Trans>
                                                    </Alert>
                                                </Collapse>
                                                <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                    <LoadingButton loading={isLoading} loadingPosition="center" startIcon={""} size="large" onClick={() => this.handleSubmit()} variant="contained" color="primary"><Trans>forgot_pwd_btn</Trans></LoadingButton>
                                                </FormControl>
                                                <FormControl variant="outlined" sx={{ pb: 2 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ flex: '50%', textAlign: 'left', pl: 1 }}><Typography variant='body2'><NavLink to='/'>Login</NavLink></Typography></Box>
                                                        <Box sx={{ flex: '50%', textAlign: 'right', pr: 1 }}><Typography variant='body2'><NavLink to='/signup'>Signup</NavLink></Typography></Box>
                                                    </Box>
                                                </FormControl>
                                            </Box>
                                            :
                                            <Box>
                                                <Alert variant='standard' color='success'>
                                                    <Typography variant="subtitle1">
                                                        <Trans>forgot_step_1</Trans>
                                                    </Typography>
                                                </Alert>
                                            </Box>
                                        }
                                    </Box>
                                </CardContent>
                            </Card>
                        </Container>
                    </BrowserView>

                    <MobileOnlyView>
                        <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                                    <img height={80} alt="Logo" src="/LogoColVert.svg" />
                                </Box>
                                <Box sx={{ pb: 4 }}>
                                    <Box sx={{ flex: '100%' }}>
                                        <Typography variant='h4' className='big'><Trans>forgot_title</Trans></Typography>
                                        <Typography variant='caption' color={'secondary'}><Trans>forgot_text</Trans></Typography>
                                    </Box>
                                </Box>
                                {!done ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, width: '100%' }}>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                onChange={e => this.handleChange('username', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailOutlined color='inherit' />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <Collapse timeout={150} in={errorOpen}>
                                            <Alert
                                                severity="warning"
                                                color='error'
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            this.setState({ errorOpen: false })
                                                        }}><Close fontSize="inherit" /></IconButton>
                                                }
                                                sx={{ mb: 2 }}>

                                                <Trans>{errorMsg}</Trans>
                                            </Alert>
                                        </Collapse>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <LoadingButton loading={isLoading} loadingPosition="center" startIcon={""} size="large" onClick={() => this.handleSubmit()} variant="contained" color="primary"><Trans>forgot_pwd_btn</Trans></LoadingButton>
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ flex: '50%', textAlign: 'left', pl: 1 }}><Typography variant='body2'><NavLink to='/'>Login</NavLink></Typography></Box>
                                                <Box sx={{ flex: '50%', textAlign: 'right', pr: 1 }}><Typography variant='body2'><NavLink to='/signup'>Signup</NavLink></Typography></Box>
                                            </Box>
                                        </FormControl>
                                    </Box>
                                    :
                                    <Box>
                                        <Alert variant='standard' color='success'>
                                            <Typography variant="subtitle1">
                                                <Trans>forgot_step_1</Trans>
                                            </Typography>
                                        </Alert>
                                    </Box>
                                }
                            </Box>
                        </Container>
                    </MobileOnlyView>
                </div>
            </Fade>
        )
    }
}
export default withTheme(PasswordReset);