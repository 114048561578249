import { CheckOutlined } from '@mui/icons-material';
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import { t } from 'i18next';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { getFlag } from '../commons/Utils';
import SkeletonList from '../elements/SkeletonListAvatar';

class MzAllocationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      allowed: true,
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/composition_details/' + this.props.id, config)
      .then(response => {
        const data = response.data
        this.setState({ data });
        this.setState({ isLoading: false })
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        if (401 === error.response.status) {
          this.fetchLiteData()
        } else {
          console.log(error);
        }
      })
    this.setState({ isLoading: false });
  }

  fetchLiteData(){
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/mz/wa/composition_details_lite/' + this.props.id, config)
      .then(response => {
        const data = response.data
        this.setState({ data });
        this.setState({ isLoading: false })
      }).catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        if (401 === error.response.status) {
          this.setState({ allowed: false })
        } else {
          console.log(error);
        }
      })
    this.setState({ isLoading: false });
  }

  render() {
    const { data, isLoading, allowed } = this.state;
    const { id, isDialogOpened, closeDialog } = this.props;

    return (
      <Dialog fullWidth id={id} open={isDialogOpened} onClose={closeDialog} scroll='paper'>
        <DialogTitle>
          <Typography variant='h6' component={'div'}>Portfolio allocation</Typography>
          <Typography variant='caption' color='secondary' component={'div'}>Last rebalance: {data ? data.last_update : '-'}</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <List>
            {
              // >>> LOADING STATE
              isLoading ? <SkeletonList /> :
                // >>> DATA STATE
                data.list && data.list.length > 0 ? data.list.map((obj) => (
                  <ListItem key={obj.instrument_name}>
                    <ListItemAvatar><Avatar src={getFlag(obj.country_name)} /></ListItemAvatar>
                    <ListItemText
                      primary={<Typography variant='subtitle2' style={{ whiteSpace: 'nowrap', maxWidth: '70%', overflow: "hidden", textOverflow: "ellipsis" }}>{obj.instrument_name}</Typography>}
                      secondary={obj.sector_name} />
                    <ListItemSecondaryAction>{obj.weight_val}</ListItemSecondaryAction>
                  </ListItem>
                )) 
                :
                // >>> NO DATA STATE
                allowed ? "no data"
                :
                // >>> NOT ALLOWED STATE
                <Unauthorized/>
          }
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant='' onClick={this.props.closeDialog} color="primary">
            <CheckOutlined color="primary"></CheckOutlined>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MzAllocationDetails;

// ---------------------
// UNAUTHORIZED CONTENT
// ---------------------
class Unauthorized extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ p: 2, flex: '40%' }}><img src={'/img/401/Upgrade401.svg'} /></Box>
          <Box sx={{ p: 2, flex: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box><Typography variant='subtitle2'>{t('MzAllocationDetails401Title')}</Typography></Box>
            <Box sx={{pb:2}}><Typography variant='body2'>{t('MzAllocationDetails401Text')}</Typography></Box>
            <Button disableElevation variant="contained" component={NavLink} to={'/plans'}>{t('MzAllocationDetails401Button')}</Button>
          </Box>
        </Box>
      </>
    )
  }
}