import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import numeral from 'numeral';
import { CardActions, CardHeader } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { withTheme } from '@emotion/react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

class DetailsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selected: '-3_months',
    }
  }
  async componentDidMount() {
    this.loadHistory()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.state.options) {
        this.loadHistory()
      }
    }
  }

  loadHistory(tf) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    tf = tf ? tf : this.state.selected
    axios.get(process.env.REACT_APP_API + 'trading/history/' + this.props.id + '/' + tf, config)
      .then(response => {
        const data = response.data

        let series = [{
          data: []
        }];
        let series_brush = [{
          data: []
        }];
        let quotes = [];
        let quotes_brush = [];
        let quote;
        let quote_brush;
        var options = {};
        var perspectiveView = {};

        for (var i = 0; i < data.length; i++) {
          quote = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes.push(quote);
          quote_brush = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes_brush.push(quote_brush);
        }
        series[0].data = quotes;
        series_brush[0].data = quotes_brush;

        var options = {
          series: series,
          chart: {
            type: 'area',
            id: 'historical',
            height: 300,
            toolbar: { show: false },
            animations: {
              enabled: false,
              easing: 'easein',
              speed: 250,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            },
            zoom: {
              enabled: false,
            },
            brush: {
              autoScaleYaxis: true
            }
          },
          stroke: {
            show: true,
            curve: "smooth",
            width: 1.5,
          },
          colors: ['#8d21e9'],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: ['#8d21e9', '#ffffff'],
              opacityFrom: 1,
              opacityTo: .1,
              stops: [5]
            }
          },
          markers: {
            size: 0
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          title: {
            show: false
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 3,
            tickPlacement: 'on',
            labels: {
              show: true,
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            tickAmount: 3,
            forceNiceScale: true,
            logarithmic: false,
            logBase: 10,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.0[0]') },
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
        };

        var perspectiveView = {
          series: series_brush,
          chart: {
            id: 'brush',
            height: 100,
            type: 'area',
            brush: {
              enabled: true,
              target: 'historical'
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date(quotes_brush[0].x).getTime(),
                max: new Date(quotes_brush[quotes_brush.length - 1].x).getTime()
              },
            },
            animations: {
              enabled: false,
            },
          },
          colors: ['var(--default-color-lightgrey, lightgrey)'],
          grid: {
            borderColor: '#e6e6e6',
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: false
            }
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            forceNiceScale: true,
            tickAmount: 3,
            labels: {
              show: false
            }
          },
          fill: {
            type: 'solid',
            colors: ['#f6f6f6'],
            opacity: .3,
          },
        };

        this.setState({ data, options, perspectiveView });

      }).catch(error => {
        console.log(error);
      })
  }

  changeTimeFrame(tf) {
    this.setState({ selected: tf })
    this.loadHistory(tf)
  }

  render() {
    const { data, selected, options } = this.state;
    const series = data
    const lastClose = data && data.length > 1 ? data[data.length - 1].c : "-"

    return (
      <Card variant="outlined">
        <CardHeader
          title={<Typography variant='overline'><Trans>{"DetailsHistoryTitle"}</Trans></Typography>}
          subheader={<Typography variant='caption' component={"div"} className='slim' color={"textSecondary"}>Last price: {lastClose}</Typography>}
        />
        <CardContent>
          {options ?
            <Box>
              <ReactApexChart options={this.state.options} series={this.state.options.series} type="area" height={350} />
              <ReactApexChart options={this.state.perspectiveView} series={this.state.perspectiveView.series} type="area" height={120} />
            </Box>
            : 'loading...'}
        </CardContent>
        <CardActions>

          {[
            { 'l': t('my_historical_3m'), 'v': '-3_months' },
            { 'l': t('my_historical_6m'), 'v': '-6_months' },
            { 'l': t('my_historical_1y'), 'v': '-1_year' },
            { 'l': t('my_historical_3y'), 'v': '-3_years' },
            { 'l': t('my_historical_all'), 'v': '-5_years' }
          ].map((item) => {
            const checked = selected === item.v;
            return (
              <Chip
                sx={{ width: "100%", maxWidth: 92 }}
                size='small'
                key={item.l}
                variant={checked ? 'plain' : 'outlined'}
                color={checked ? 'primary' : 'secondary'}
                label={item.l}
                startDecorator={
                  checked && <CheckOutlined sx={{ zIndex: 1, pointerEvents: 'none' }} />
                }
                onClick={() => {
                  this.changeTimeFrame(item.v);
                }}
              >

              </Chip>
            );
          })}

        </CardActions>
      </Card>
    );
  }
}

export default withTheme(DetailsHistory);
