import { withTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { t } from 'i18next';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import SkeletonChart from '../../elements/SkeletonChart';

class MobDetailsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      selected: '-3_months',
    }
  }
  async componentDidMount() {
    this.loadHistory()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.state.options) {
        this.loadHistory()
      }
    }
  }

  loadHistory(tf) {
    this.setState({ isLoading: true });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    tf = tf ? tf : this.state.selected
    axios.get(process.env.REACT_APP_API + 'trading/history/' + this.props.id + '/' + tf, config)
      .then(response => {
        const data = response.data

        let series = [{
          data: []
        }];
        let series_brush = [{
          data: []
        }];
        let quotes = [];
        let quotes_brush = [];
        let quote;
        let quote_brush;
        var options = {};
        var perspectiveView = {};

        for (var i = 0; i < data.length; i++) {
          quote = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes.push(quote);
          quote_brush = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes_brush.push(quote_brush);
        }
        series[0].data = quotes;
        series_brush[0].data = quotes_brush;

        var options = {
          series: series,
          chart: {
            type: 'area',
            id: 'historical',
            toolbar: { show: false },
            animations: {
              enabled: true,
              easing: 'easeout',
              speed: 550,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: false,
                speed: 350
              }
            },
            zoom: {
              enabled: false,
            },
            brush: {
              autoScaleYaxis: true
            }
          },
          stroke: {
            show: true,
            curve: "smooth",
            width: 1.5,
          },
          colors: ['#8d21e9'],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: ['#8d21e9', '#ffffff'],
              opacityFrom: 1,
              opacityTo: .1,
              stops: [5]
            }
          },
          markers: {
            size: 0
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          title: {
            show: false
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 3,
            tickPlacement: 'on',
            labels: {
              show: true,
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            min: function (min) { return min },
            max: function (max) { return max },
            tickAmount: 3,
            forceNiceScale: true,
            logarithmic: false,
            logBase: 10,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.0[0]') },
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
        };

        var perspectiveView = {
          series: series_brush,
          chart: {
            id: 'brush',
            height: 100,
            type: 'area',
            brush: {
              enabled: true,
              target: 'historical'
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date(quotes_brush[0].x).getTime(),
                max: new Date(quotes_brush[quotes_brush.length - 1].x).getTime()
              },
            },
            animations: {
              enabled: false
            },
          },
          colors: ['var(--default-color-lightgrey, lightgrey)'],
          grid: {
            borderColor: '#e6e6e6',
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: false
            }
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          fill: {
            type: 'solid',
            colors: ['#f6f6f6'],
            opacity: .3,
          },
        };

        this.setState({ data, options, perspectiveView });

      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false })
      })
  }

  changeTimeFrame(tf) {
    this.setState({ selected: tf })
    this.loadHistory(tf)
  }

  render() {
    const { isLoading, selected, options } = this.state;
    return (
      <div>
        <Box><Typography variant='overline'>{t("DetailsHistoryTitle")}</Typography></Box>
        {
          options ?
            <Box>
              <ReactApexChart options={this.state.options} series={this.state.options.series} type="area" height={'auto'} />
            </Box>
            : <SkeletonChart height={240} />
        }
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Grid container maxWidth={300} spacing={1}>
            {[
              { 'l': '1 month', 'v': '-1_months' },
              { 'l': '3 months', 'v': '-3_months' },
              { 'l': '6 months', 'v': '-6_months' },
              { 'l': '1 year', 'v': '-1_year' },
              { 'l': '3 years', 'v': '-3_years' },
              { 'l': 'All', 'v': '-5_years' }
            ].map((item) => {
              const checked = selected === item.v;
              return (
                <Grid item xs={4}>
                  <Chip
                    sx={{ width: "100%", maxWidth: 92 }}
                    size='medium'
                    key={item.l}
                    disabled={isLoading ? true : false}
                    variant={checked ? 'plain' : 'outlined'}
                    color={checked ? 'primary' : 'secondary'}
                    label={item.l}
                    onClick={() => this.changeTimeFrame(item.v)}
                  >

                  </Chip>
                </Grid>
              );
            })}
          </Grid>
        </Box>

      </div>
    );
  }
}

export default withTheme(MobDetailsHistory);
