import { HelpOutlineOutlined as HelpOutlineOutlinedIcon, SearchOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardContent, CardHeader, IconButton, InputAdornment, Slide, Typography } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import axios from 'axios';
import { t } from 'i18next';
import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../commons/Colors';
import { getFlag } from '../commons/Utils';
import DialogHelp from '../elements/DialogHelp';
import DialogTrade from '../elements/DialogTrade';
import SkeletonListAvatar from '../elements/SkeletonListAvatar';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        label={t('myst_trades_filterbyname')}
        placeholder={''}
        variant='outlined'
        size='small'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}


        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MyStatsTrades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      posConfirmOpen: false,
      posConfirmTradeId: 0,
      idTrade: 0,

      columns: [],
      rows: [],

      posDialogOpen: false,
      posDialogTitle: "",
      posDialogHist: [],
      posDialogData: [],
      max_min_items: [],

      helpOpen: false,
      helpText: ""
    }
  }

  addPosition(id) {
    this.setState({ posConfirmOpen: true, posConfirmTradeId: id });
  }

  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'v2/myst/wa/trades/500/0/' + localStorage.getItem('ID'), config)
      .then(response => {
        const data = response.data
        const trades = data.trades
        const columns = [
          {
            field: 'country', headerName: '', width: '30',
            sortable: false, filterable: false, hideable: false, disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <Avatar src={params.value.avatar} />
              );
            }
          },
          {
            field: 'name',
            renderCell: (params) => {
              return (
                <Typography variant="subtitle2">{params.value}</Typography>
              );
            },
            headerName: t('myst_trades_name'), filterable: true, hideable: false, disableColumnMenu: true, flex: 2
          },
          { field: 'open_signal', headerName: t('myst_trades_direction'), filterable: false, hideable: false, disableColumnMenu: true, description: 'Direction: long/short', flex: 1 },
          { field: 'open_date', headerName: t('myst_trades_opendate'), filterable: false, hideable: false, disableColumnMenu: true, type: 'number', flex: 1 },
          { field: 'open_price', headerName: t('myst_trades_openprice'), filterable: false, hideable: false, sortable: false, disableColumnMenu: true, type: 'number', flex: 1 },
          { field: 'exit_date', headerName: t('myst_trades_exitdate'), filterable: false, hideable: false, disableColumnMenu: true, type: 'number', flex: 1 },
          { field: 'exit_price', headerName: t('myst_trades_exitprice'), filterable: false, hideable: false, sortable: false, disableColumnMenu: true, type: 'number', flex: 1 },
          {
            field: 'perc_gain',
            renderCell: (params) => {
              let col = parseFloat(params.value) > 0 ? COLORS.green : COLORS.red
              return (
                <Typography variant='body2' sx={{ color: col }}>{numeral(params.value).format('+0.00') + '%'}</Typography>
              );
            },
            headerName: t('myst_trades_profit'), type: 'number', flex: 1
            , filterable: false, hideable: false, disableColumnMenu: true
          },
        ];

        let rows = [
        ];

        trades.forEach((row) => {
          rows.push({
            id: row.id_trade,
            country: { avatar: getFlag(row.country) },
            name: row.name,
            open_signal: row.open_signal,
            open_date: moment('20' + row.open_date).format('DD/MM/YYYY'), 
            open_price: numeral(row.open_price).format('1,000.00'),
            exit_date: moment('20' + row.exit_date).format('DD/MM/YYYY'),
            exit_price: numeral(row.exit_price).format('1,000.00'),
            perc_gain: row.perc_gain
          })
        });

        this.setState({ isLoading: false, data, columns, rows });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  closePos() {
    this.setState({ posDialogOpen: false, posDialogTitle: "", posDialogHist: [], posDialogData: [] });
  }

  loadPosition = (idTrade) => {
    this.setState({ posDialogOpen: true, idTrade });
  }

  handleRowClick = (params) => {
    console.log(params);
    this.loadPosition(params.id)
  };

  render() {
    const { data, columns, rows, isLoading, posDialogOpen, posDialogTitle, posDialogHist, posDialogData, max_min_items, options, idTrade } = this.state;
    let trades = data ? data.trades : []
    return (
      <div>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={<Trans>MyStatsTradesTitle</Trans>}
          helpDialogContent={
            <Trans
              components={{
                b: <strong />,
                em: <em />,
              }}
            >
              MyStatsTradesHelp
            </Trans>
          }
          okButton="ok" />

        <DialogTrade
          open={posDialogOpen}
          closePos={() => this.closePos()}
          TransitionComponent={Transition}
          posDialogData={posDialogData}
          posDialogHist={posDialogHist}
          posDialogTitle={posDialogTitle}
          max_min_items={max_min_items}
          options={options}
          idTrade={idTrade}
          theme={this.props.theme}
        />

        <Card variant="outlined">
          <CardHeader
            title={<Typography gutterBottom variant="overline" component="h2"><Trans>MyStatsTradesTitle</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          ></CardHeader>
          <CardContent >
            <Box sx={{ width: '100%' }}>
              {
              isLoading ? <SkeletonListAvatar /> :
              rows && rows.length > 0 ?
                <DataGrid
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  sx={{
                    borderColor: COLORS.gridgrey
                  }}
                  density='comfortable'
                  onRowClick={this.handleRowClick} {...data}
                  onCellClick=""
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  autoHeight={true}
                  hideFooterSelectedRowCount={true}
                ></DataGrid>
                : <Empty img={this.constructor.name} />
              }
            </Box>
          </CardContent>
        </Card>
      </div >
    );
  }
}

export default MyStatsTrades;

// ---------------------
// EMPTY CONTENT
// ---------------------
class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
    }
  }

  render() {
    return (
      <>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ p: 2, flex: '50%', display: 'flex', alignItems: 'center' }}><img src={'/img/empty/MyStatsTradesEmpty.svg'} alt={this.props.img} style={{maxWidth: "100%"}} /></Box>
          <Box sx={{ p: 2, flex: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box><Typography gutterBottom variant='subtitle2'><Trans>{t('myst_trades_emptyTitle')}</Trans></Typography></Box>
            <Box><Typography variant='body2'><Trans>{t('myst_trades_empty')}</Trans></Typography></Box>
            <Box sx={{ mt: 4 }}><Button component={NavLink} to='/mybraino' variant='outlined'><Trans>{t('myst_trades_empty_cta')}</Trans></Button></Box>
          </Box>
        </Box>
      </>
    )
  }
}