import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { CardHeader, IconButton, List, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { HelpOutline } from '@mui/icons-material';
import DialogHelp from '../elements/DialogHelp';
import { Trans } from 'react-i18next';

class DetailsWinseries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      helpOpen: false,
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('TOKEN')
      }
    }
    let data = axios.get(process.env.REACT_APP_API + 'trading/winseries/' + localStorage.getItem('ID') + '/' + this.props.id + '/theta2', config)
      .then(response => {
        const data = response.data
        this.setState({ data });
      }).catch(error => {
        console.log(error);
      })
  }
  
  closeHelpDialog() {
    this.setState({ helpOpen: false })
  }

  openHelpDialog() {
    this.setState({ helpOpen: true })
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <DialogHelp
          closeHelpDialog={() => this.closeHelpDialog()}
          isHelpDialogOpen={this.state.helpOpen}
          helpDialogTitle={"DetailsWinseriesTitle"}
          helpDialogContent={<Trans 
            components={{ ul: <List></List>, li: <ListItem></ListItem>, subtitle2: <Typography variant='subtitle2'></Typography> }}>
              {"DetailsWinseriesHelp"}
              </Trans>}
          okButton="ok" />

        <Card variant="outlined">
          <CardHeader
            title={<Typography variant="overline" component="h1"><Trans>{"DetailsWinseriesTitle"}</Trans></Typography>}
            action={
              <IconButton aria-label="settings" onClick={() => this.openHelpDialog()}>
                <HelpOutline />
              </IconButton>
            }
          />
          <CardContent sx={{ display: 'flex' }}>
            <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
              <Box><Typography variant="h4" className='big'>{data.last_series_count}</Typography></Box>
              <Box><Typography variant="body1"><Trans>winseries_Last</Trans></Typography></Box>
              <Box><Typography variant="caption" color="secondary">{data.last_series_start} - {data.last_series_end}</Typography></Box>
            </Box>
            <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
              <Box><Typography variant="h4" className='big'>{data.best_series_count}</Typography></Box>
              <Box><Typography variant="body1"><Trans>winseries_Best</Trans></Typography></Box>
              <Box><Typography variant="caption" color="secondary">{data.best_series_start} - {data.best_series_end}</Typography></Box>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
}

export default DetailsWinseries;
