import React, { Component } from 'react';
import { BottomNavigation, BottomNavigationAction, Chip, Grid, Typography, Fade, Container, Box, Toolbar } from '@mui/material';
import MyPositions from '../cards/MyPositions';
import MyAdvices from '../cards/MyAdvices';
import MyFavorites from '../cards/MyFavorites';
import { BrowserView, MobileOnlyView, SmartTVView } from 'react-device-detect';
import { Bookmark, BookmarkBorderOutlined, BookmarkOutlined, Favorite, FavoriteBorder, HomeOutlined, MonitorHeart, MonitorHeartOutlined, TipsAndUpdates, TipsAndUpdatesOutlined } from '@mui/icons-material';
import MobMyAdvices from '../cards/smartphone/MobMyAdvices';
import MobMyPositions from '../cards/smartphone/MobMyPositions';
import MobMyFavorites from '../cards/smartphone/MobMyFavorites';
import { Link, NavLink } from 'react-router-dom';
import FullDialog from '../elements/FullDialog';
import { withTheme } from '@emotion/react';
import IntroMyBraino from '../intro/IntroMyBraino';
import { Trans } from 'react-i18next';
import notificationsInit from '../commons/Notifications';
import { GlobalContext } from '../commons/GlobalContext';
import MobIntroMyBraino from '../intro/MobIntroMyBraino';
import { connect } from 'react-redux';
import { notifyLoginUpdates, notifyLogout, setMainTitle } from '../reduxActions/Actions';
import { t } from 'i18next';

class MyBraino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMyStatsClickable: true,
      myStatsColor: 'primary',
      isMainClickable: false,
      mainColor: 'default',
      view: 'main',
      mobile_view: 1,

      schedeOpen: false,

      reloadMyPositions: false,
      reloadMyFavorites: false,
      reloadMyAdvices: false,

      selected: 'main',

      introOpen: localStorage.getItem("ONB_MYBRAINO") ? false : true
    }
  }
  handleNotificationAdvicesChange() {
    this.setState({ reloadMyAdvices: true });
    setTimeout(function () { this.setState({ reloadMyAdvices: false }) }.bind(this), 1000);
  }

  handleNotificationNewPosition(idTrade) {
    this.setState({ reloadMyPositions: true });
    setTimeout(function () { this.setState({ reloadMyPositions: false }) }.bind(this), 1000);
  }

  handleNotificationFavoriteChange() {
    this.setState({ reloadMyFavorites: true, reloadMyAdvices: true });
    setTimeout(function () { this.setState({ reloadMyFavorites: false, reloadMyAdvices: false }) }.bind(this), 1000);
  }

  switchView(view) {
    this.setState({ view, selected: view });
  }

  switchMobileView(mobile_view) {
    this.setState({ mobile_view, selected: mobile_view });
  }

  componentDidMount() {
    if (!localStorage.getItem("TOKEN")) { 
      this.props.dispatch(notifyLogout()) 
      this.props.dispatch(notifyLoginUpdates()) 
    }
    this.props.dispatch(setMainTitle(t("onboarding_home2_title")))
    // notificationsInit(localStorage.getItem('MSGT'));
  }

  closeSchede() {
    this.setState({ schedeOpen: false })
  }

  introOpen() {
    this.setState({
      introOpen: true
    });
  }
  introClose() {
    this.setState({
      introOpen: false
    });
  }

  render() {
    const { favoritesNeededReload, mobile_view, schedeOpen, reloadMyPositions, reloadMyFavorites, reloadMyAdvices, introOpen } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          <FullDialog open={schedeOpen} close={() => this.closeSchede()}></FullDialog>
          <BrowserView>
            <Toolbar />
            <IntroMyBraino
              open={introOpen}
              introClose={() => this.introClose()}
              title={'Intro'}
            />
            <Container sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="h4" className='big'><Trans>my_sectionTitle1</Trans></Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign='right'>
                  <Chip
                    clickable
                    component={NavLink} to={'/mystats'} button
                    sx={{ mr: 1 }}
                    size='medium'
                    variant={'plain'}
                    color={'primary'}
                    label='Go to MyStats'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <MyAdvices prefix="my_open_advice" notifyNewPosition={this.handleNotificationNewPosition.bind(this)} neededReload={reloadMyAdvices}></MyAdvices>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MyPositions prefix="my_mypositions" notifyNewPosition={this.handleNotificationNewPosition.bind(this)} neededReload={reloadMyPositions}></MyPositions>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <MyFavorites prefix="my_favorites"
                      notifyFavoriteChange={this.handleNotificationFavoriteChange.bind(this)}
                      notifyAdvicesChange={this.handleNotificationAdvicesChange.bind(this)}
                      neededReload={reloadMyFavorites}></MyFavorites>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </BrowserView>

          <MobileOnlyView>
            <MobIntroMyBraino
              open={introOpen}
              introClose={() => this.introClose()}
              title={'Intro'}
            />
            <Toolbar />
            <Container sx={{ p: 0 }}>
              <Grid container spacing={1}>
                {mobile_view === 1 ? (
                  <Fade in={mobile_view === 1} timeout={450}>
                    <Grid item xs={12}>
                      <MobMyAdvices prefix="my_open_advice" notifyNewPosition={this.handleNotificationNewPosition.bind(this)}></MobMyAdvices>
                    </Grid>
                  </Fade>
                ) : mobile_view === 2 ? (
                  <Fade in={mobile_view === 2} timeout={450}>
                    <Grid item xs={12}>
                      <MobMyPositions prefix="my_mypositions" notifyNewPosition={this.handleNotificationNewPosition.bind(this)} neededReload={reloadMyPositions}></MobMyPositions>
                    </Grid>
                  </Fade>
                ) : mobile_view === 3 ? (
                  <Fade in={mobile_view === 3} timeout={450}>
                    <Grid item xs={12}>
                      <MobMyFavorites prefix="my_favorites" neededReload={favoritesNeededReload} notifyFavoriteChange={this.handleNotificationFavoriteChange.bind(this)}></MobMyFavorites>
                    </Grid>
                  </Fade>
                ) : 'No view selected'}
              </Grid>
              <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
                <BottomNavigation
                  value={mobile_view}
                  onChange={(event, newValue) => {
                    this.switchMobileView(newValue);
                  }}
                  showLabels

                >
                  <BottomNavigationAction label="Home" component={Link} to="/home" value="0" icon={<HomeOutlined />} />
                  {[
                    { 'l': 'Advices', 'v': 1, iconOn: <TipsAndUpdates />, iconOff: <TipsAndUpdatesOutlined /> },
                    { 'l': 'Trades', 'v': 2, iconOn: <MonitorHeart />, iconOff: <MonitorHeartOutlined /> },
                    { 'l': 'Favs', 'v': 3, iconOn: <Bookmark />, iconOff: <BookmarkBorderOutlined /> },
                  ].map((item) => {
                    const checked = mobile_view === item.v;
                    return (
                      <BottomNavigationAction label={item.l} value={item.v} icon={checked ? item.iconOn : item.iconOff} />
                    )
                  })
                  }
                </BottomNavigation>
              </Box>
            </Container>
          </MobileOnlyView>

          <SmartTVView>Display not ready</SmartTVView>
        </div>
      </Fade>);
  }
}
MyBraino.contextType = GlobalContext;

export default withTheme(connect()(MyBraino));