import { Box, Button, Container, Fade, Toolbar, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Component } from 'react';
import { MobileOnlyView, isBrowser, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { setMainTitle } from '../reduxActions/Actions';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PaymentCanceled extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentType: 0,
        }
    }

    componentDidMount() {
        this.props.dispatch(setMainTitle(t("paypal_cancelTitle")))
        let { paymentType } = this.props.params;
        this.setState({ paymentType })
    }

    render() {
        return (
            <Fade in={true} timeout={450}>
                <div>
                    <Toolbar />
                    {(isBrowser || isTablet) && (
                        <Container sx={{ p: 2 }}>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant='h3' gutterBottom>{t("paypal_cancelTitle")}</Typography>
                                {this.state.paymentType === "stripe" ?
                                    <div><Typography variant='h6' sx={{ fontWeight: 300 }}>{t("stripe_failed_subtitle1")}</Typography>
                                        <Typography variant='h6' sx={{ fontWeight: 300 }}>{t("stripe_failed_subtitle2")}</Typography></div>
                                    : this.state.paymentType === "paypal" ?
                                        <div><Typography variant='h6' sx={{ fontWeight: 300 }}>{t("paypal_cancel_subtitle1")}</Typography></div>
                                        : "Niente"
                                }
                            </Box>
                            <Box sx={{ textAlign: "center", py: 2 }}>
                                <img src="img/ecommerce/paymentCanceled.svg" style={{ maxHeight: 400, maxWidth: "100%" }} />
                            </Box>
                            <Box sx={{ textAlign: "center", py: 2 }}>
                                <Button component={NavLink} to={"/home"} size='large' disableElevation sx={{ mr: 1 }}>{t("ecommerceBackButton")}</Button>
                                <Button component={NavLink} to={"/plans"} variant='contained' size='large' disableElevation>Ecommerce</Button>
                            </Box>
                        </Container>
                    )}

                    <MobileOnlyView>
                        <Container sx={{ p: 2, display: "flex", flexDirection: "column", height: "calc(100vh - 56px)", overflow: "hidden" }}>
                            <Box sx={{}}>
                                <Typography variant='h4'>{t(`${this.state.paymentType}_cancelTitle`)}</Typography>
                                <Typography variant='body1' sx={{ fontWeight: 300 }}>{t(`${this.state.paymentType}_cancel_subtitle1`)}</Typography>
                            </Box>
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                                <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                                    <img src="img/ecommerce/paymentCanceled.svg" alt="Payment canceled" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "contain" }} />
                                </Box>
                            </Box>
                            <Box sx={{ height: 75, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                            <Button component={NavLink} to={"/home"} size='large' disableElevation sx={{ mr: 1 }}>{t("ecommerceBackButton")}</Button>
                                <Button component={NavLink} to={"/plans"} variant='contained' size='large' disableElevation>Ecommerce</Button>
                            </Box>
                        </Container>
                    </MobileOnlyView>
                </div>
            </Fade>
        );
    }
}

export default withParams(connect()(PaymentCanceled));